import React from 'react';
import { Redirect } from 'react-router-dom';
import { NiftyAuthenticationState, NiftyRenderState, OnboardState, useAuth } from '../../contexts/AuthContext';
import Loading from '../loading/index';

const defaultProps = {

};
type IProps = {
    children: React.ReactElement,
} & typeof defaultProps;

const Commonwrap = function (props: IProps): React.ReactElement {
    const { authState, renderState, onboardState } = useAuth()

    if (authState === NiftyAuthenticationState.UNAUTHENTICATED && !(/^\/admin\/login/.test(window.location.pathname))) {
        return <Redirect to="/admin/login" />
    } else if (onboardState === OnboardState.ONBOARDING && !(/^\/admin\/onboard/.test(window.location.pathname))) {
        return <Redirect to="/admin/onboard" />
    } else if (authState === NiftyAuthenticationState.LOADING) {
        return <Loading show fullScreen />;
    } else {
        // if (renderState === NiftyRenderState.LOADED) {
        //     return props.children
        // } else {
        //     return <Loading show fullScreen />
        // }
        return props.children
    }

}

Commonwrap.defaultProps = defaultProps;
export default Commonwrap