import store from './store';
import actions from './actions/creator';

export async function setLinks(payload) {
    await store.dispatch(actions.setLinks(payload))
}
export async function updateLink(payload) {
    await store.dispatch(actions.updateLink(payload))
}

export async function deleteLink(payload) {
    await store.dispatch(actions.deleteLink(payload))
}
