import { useLocation } from "react-router-dom";

export default function Error404() {
  let location = useLocation();

  // TODO: find a way to set the response code
  return (
    <div>
      <h3>
        Not found: <code>{location.pathname}</code>
      </h3>
    </div>
  );
}
