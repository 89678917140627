import { library } from '@fortawesome/fontawesome-svg-core';


import {
    faEllipsisV,
    faTrashAlt,
    faPencil,
    faEye,
    faTimes,
    faExternalLink,
    faShareAlt,
    faBadgeCheck,
    faGlobe,
    faChartNetwork,
    faAngleRight,
    faCloudUpload,
    faUserFriends,
    faEyeSlash
} from '@fortawesome/pro-solid-svg-icons';
import {
    faBan,
    faUserCircle,
    faPlus,
    faCheckCircle,
    faArrowDown
} from '@fortawesome/pro-light-svg-icons';

import {
    faGithubAlt,
    faGoogle,
    faFacebook,
    faTwitter,
    faDiscord,
    faInstagramSquare,
    faLinkedin,
    faEthereum,
    faTelegram
} from '@fortawesome/free-brands-svg-icons';

library.add(
    //brand
    faGithubAlt,
    faGoogle,
    faFacebook,
    faTwitter,
    faDiscord,
    faInstagramSquare,
    faLinkedin,
    faEthereum,
    faTelegram,
    faEyeSlash,
    //solid
    faEllipsisV,
    faTrashAlt,
    faPencil,
    faEye,
    faTimes,
    faExternalLink,
    faShareAlt,
    faArrowDown,
    faBadgeCheck,
    faGlobe,
    faChartNetwork,
    faAngleRight,
    faCloudUpload,
    faUserFriends,
    //light
    faBan,
    faUserCircle,
    faPlus,
    faCheckCircle,

);