import axios from "axios"
import { Ilink } from "../types"


export const getLinks = async (uid: string) => {
    const { data } = await axios.get<Ilink[]>(`/api/userlinks/index.json`, {
        params: {
            user_id: uid
        }
    })
    return data.map(v => {
        v.status = v.status ? true : false
        return v
    })
}

export const sortLinks = async (data: { id: number, sort: number }[]) => {
    return await axios.put(`/api/userlinks/sort.json`, {
        links: data
    })
}

export const addLink = async (data: { [key: string]: any }) => {
    if (data.status !== undefined) {
        data.status = data.status ? 1 : 0
    }
    return await axios.post(`/api/userlinks.json`, {
        link: data
    })
}

export const editLink = async (data: { [key: string]: any }) => {
    if (data.status !== undefined) {
        data.status = data.status ? 1 : 0
    }
    return await axios.put(`/api/userlinks/${data.id}.json`, {
        link: data
    })
}

export const delLink = async (id: number) => {
    return await axios.delete(`/api/userlinks/${id}.json`)
}