import { createActions } from 'redux-actions';
import types from './types';

let actions = {}

Object.keys(types).forEach(k => {
  actions[k] = (payload) => {
    return payload
  }
})

export default createActions(actions);