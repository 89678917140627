import { Button, Container, Dropdown, Image } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import style from './Index.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Images from '../../../images';
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { useState } from 'react'

export default function Niftynetlink() {
    const userInfo = useSelector((state: any) => state.user)
    const [showDropMenu, setshowDropMenu] = useState<boolean>(false)

    const shareFacebook = () => {
        setshowDropMenu(false)
        const link = `${window.location.origin}/${userInfo.username}`
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${link}`, '_block')
    }

    const shareTwitter = () => {
        setshowDropMenu(false)
        const param = encodeURI(`Check out this account on NiftyNet&url=${window.location.origin}/${userInfo.username}&via=NiftyNet`)
        const link = `https://twitter.com/intent/tweet?text=${param}`
        window.open(link, '_block')
    }

    return <div className={style.link}>
        <Container>
            <div className={style.body}>
                <p className='ellipsis'>My NiftyNet: <a href={`${window.location.origin}/${userInfo?.username}`} rel="noreferrer" target={'_blank'}>{window.location.origin}/{userInfo?.username}</a></p>

                <div className={style.dropmenu}>
                    <Button variant='outline-secondary' onClick={() => {
                        setshowDropMenu(!showDropMenu)
                    }}>Share</Button>
                    {showDropMenu &&
                        <div className={style.mask} onClick={() => {
                            setshowDropMenu(false)
                        }}></div>
                    }
                    <Dropdown.Menu show={showDropMenu} style={{ right: 0, marginTop: '0.2rem' }}>
                        {userInfo?.username &&
                            <Dropdown.Item >
                                <CopyToClipboard text={`${window.location.origin}/${userInfo.username}`}
                                    onCopy={() => {
                                        toast.info('Link copied!')
                                        setshowDropMenu(false)
                                    }}>
                                    <div className={style.shareItem}>
                                        <Image src={Images.logo} roundedCircle></Image>
                                        <span>Copy Link</span>
                                    </div>
                                </CopyToClipboard>

                            </Dropdown.Item>
                        }
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => {
                            shareFacebook()
                        }} >
                            <div className={style.shareItem}>
                                <FontAwesomeIcon icon={["fab", "facebook"]} size={'2x'} color='#1977F2' />
                                <span>Share on Facebook</span>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => {
                            shareTwitter()
                        }}>
                            <div className={style.shareItem}>
                                <FontAwesomeIcon icon={["fab", "twitter"]} size={'2x'} color='#1C9CF0' />
                                <span>Share to Twitter</span>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </div>
            </div>
        </Container>
    </div>
}
