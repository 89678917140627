import axios from "axios"

export const checkSync = async () => {
    const response = await axios.get(`/api/sync/checkIsSyncing.json`)
    return response.data
}

export const sync = async () => {
    const response = await axios.get(`/api/sync/sync.json`)
    return response.data
}
