import React from 'react';

export default function Grid({ children, columns }: any) {
    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${columns}, 1fr)`,
                gridGap: 10,
            }}
        >
            {children}
        </div>
    );
}
