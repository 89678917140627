import style from './Linkspreview.module.scss'
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


const defaultProps = {

};
type IProps = {
    type?: 'desktop' | 'mobile'
} & typeof defaultProps;

const Linkspreview = (props: IProps) => {
    const { type } = props
    const [src, setSrc] = useState<string>()
    const userInfo = useSelector((state: any) => state.user)
    const links = useSelector((state: any) => state.links.links)
    const [transform, setTransform] = useState<string>('scale(0.7) translateX(-50%) translateY(-50%) translate3d(0px, 0px, 0px)')

    useEffect(() => {
        const timer = setTimeout(() => {
            setSrc(`${window.location.origin}/${userInfo.username}?timestamp=` + (new Date()).getTime())
        }, 1000);
        return () => {
            timer && clearTimeout(timer)
        }
    }, [userInfo, links])

    const bindResize = () => {
        const h = document.documentElement.clientHeight
        let scale = (h / 1000).toFixed(2)
        setTransform(`scale(${scale}) translateX(-50%) translateY(-50%) translate3d(0px, 0px, 0px)`)
    }

    useEffect(() => {
        window.addEventListener('resize', bindResize);
        bindResize()        
        // return window.removeEventListener("resize", bindResize);//移除
    }, [])


    return type === 'desktop' ? <div className={style.desktop}>
        <div className={style.container} id='desktopPreview'>
            <iframe title={'1'} src={src} frameBorder="0" style={{
                width: '100%',
                height: '100%'
            }}></iframe>
        </div>
    </div> :
        <div className={style.mobile} style={{ transform: transform }}>
            <div className={style.container} id='mobilePreview'>
                <iframe title={'1'} src={src} frameBorder="0" style={{
                    width: '100%',
                    height: '100%'
                }}></iframe>
            </div>
        </div>
}

export default Linkspreview