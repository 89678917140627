import style from './Link.module.scss'
import Avatar from "../../components/avatar"
import { Ilink, Itoken, Iuser } from "../../types";
import Grid from '../grid/Grid';
import GridBootstrap from '../grid/Index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroup, Button, Modal, Image, Container, Dropdown } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import Config from '../../utils/config';
import Item from '../grid/Item';
import { Helmet } from 'react-helmet-async';
import Images from '../../images';
import { getLinks } from '../../api/links';
import { getTokens, getToken } from '../../api/tokens';
import Loading from '../loading';
import ScrollLoading from '../scrollLoading';
import Viewer from 'react-viewer';
import Hexagon from '../avatar/Hexagon';
import ReactMarkdown from 'react-markdown'

const defaultProps = {

};
type TProps = {
    user?: Iuser,
} & typeof defaultProps;


const Link = (props: TProps) => {
    const { user } = props
    const [tokenDetail, setTokenDetail] = useState<any>(null)
    const [enableLoadTokens, setEnableLoadTokens] = useState<boolean>(false)
    const [showDropMenu, setShowDropMenu] = useState<boolean>(false)

    const [links, setLinks] = useState<Ilink[]>([])
    const [nfts, setNfts] = useState<Itoken[]>([])
    const [pageLoading, setPageLoading] = useState<boolean>(false)
    const [fullLoad, setFullLoad] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [viewerImgs, setViewerImgs] = useState<{ src: string, alt: string }[]>([])

    const [topTokens, setTopTokens] = useState<Itoken[]>([])

    useEffect(() => {
        if (user?.top_tokens) {
            let top_tokens = user.top_tokens.filter(v => {
                return v !== null
            })
            if (top_tokens.length > 0) {
                setTopTokens(top_tokens)
            } else {
                getTokens(user.id, 1, 8).then(res => {
                    setTopTokens(res)
                })
            }
        }
    }, [user?.id, user?.top_tokens])

    useEffect(() => {
        if (user?.id) {
            getLinks(user.id).then(res => {
                setLinks(res)
            })
        }
    }, [user?.id])

    useEffect(() => {
        if (user?.id && page > 0) {
            if (fullLoad || pageLoading) {
                return
            }
            setPageLoading(true)
            getTokens(user.id, page || 1, 20).then(res => {
                if (res.length === 0) {
                    setFullLoad(true)
                    return
                }
                if (page === 1) {
                    setNfts(res)
                } else {
                    setNfts((preState) => {
                        return [...preState, ...res]
                    })
                }
            }).finally(() => {
                setPageLoading(false)
            })
        }
    }, [user?.id, page])

    useEffect(() => {
        if (enableLoadTokens) {
            setPage(1)
        }
    }, [enableLoadTokens])

    const handleClose = () => {
        setTokenDetail(null)
    }

    const getAvatarToken = async (data: any) => {
        const token = await getToken(data.id)
        if (token) {
            setTokenDetail(token)
        }
    }


    const background = user?.config ? user.config.background : 'none'
    const color = user?.config ? (user.config.color || '#000000') : '#000000'

    let btnClassname = [style.linkitem]
    let btnStyle: { [key: string]: any } = {}
    if (user?.config) {
        if (user.config.buttonRadius) {
            btnClassname.push(style[user.config.buttonRadius])
        }
        if (user.config.buttonType) {
            btnClassname.push(style[user.config.buttonType])
        }
        btnStyle['borderColor'] = user.config.buttonColor || '#aa57cd'
        btnStyle['backgroundColor'] = user.config.buttonColor || '#aa57cd'
        btnStyle['color'] = user.config.buttonFontColor || '#FFFFFF'
    }

    let avatar = Images.default_avatar
    if (user?.avatar) {
        if (user.avatar.length > 0) {
            avatar = user.avatar
        }
    }

    const Verified = avatar.includes('source=nft')

    let name = ''
    if (user?.display_name) {
        name = user.display_name
    } else if (user?.username) {
        name = '@' + user.username
    }

    const token_count = user?.token_count || 0

    const showLoadBtn = token_count > 8 || (topTokens.length < token_count)

    return <div className='fontFamilyWrapper' style={{ color: color }}>
        <Helmet>
            <title>{name} | {Config.siteName}</title>
            <link rel="stylesheet" href={'https://fonts.googleapis.com/css2?' + Config.Fonts.map(f => {
                return 'family=' + f.replaceAll(' ', '+')
            }).join('&')} />
            <style type="text/css">{`
                .fontFamilyWrapper {
                    font-family: ${user?.config?.fontFamily}
                }
            `}</style>
        </Helmet>
        <div className={style.bg} style={{ background: background }}></div>
        <div className={style.container}>
            <div>
                <Container>
                    {user &&
                        <div className={style.profile}>
                            {Verified ?
                                <div className={style.avatar} onClick={() => {
                                    const url = new URL(avatar)
                                    let d: { [key: string]: any } = {}
                                    url.searchParams.forEach((v, k) => {
                                        d[k] = v
                                    })
                                    getAvatarToken(d)
                                }}>
                                    <Hexagon src={avatar} size="7rem" />
                                </div> : <div className={style.avatar} onClick={() => {
                                    setViewerImgs([{
                                        src: avatar,
                                        alt: ''
                                    }])
                                }}>
                                    <Avatar roundedCircle size="6em" src={avatar} border="4px" borderColor='#fff' />
                                </div>}
                            {user.accounts &&
                                <div className={style.tooltip}>
                                    <CopyToClipboard text={user.accounts[0].account}
                                        onCopy={() => {
                                            toast.info('Copied!')

                                        }}>
                                        <div className={style.wallet} style={{ borderColor: color }}>{user.accounts[0].account.slice(0, 5) + '...' + user.accounts[0].account.slice(0. - 3)}</div>
                                    </CopyToClipboard>
                                </div>
                            }
                            <h3 >{name}</h3>
                            {user.bio && <h2 className={style.bio}>{user.bio}</h2>}

                            <ButtonGroup aria-label="share" style={{ borderRadius: '0.25rem' }}>
                                {user.social_links && Object.keys(user.social_links).map(k => {
                                    let link = Config.social_links[k]
                                    if (link === undefined) {
                                        return null
                                    }
                                    link['value'] = user.social_links[k] || ''

                                    if (link.value.length > 0) {
                                        return <Button as='a' target='_blank' href={`https://${link.host}/${link.value}`} key={link.name} variant="outline-secondary" ><FontAwesomeIcon icon={link.icon as any} color={color} /></Button>
                                    } else {
                                        return null
                                    }
                                })}
                                <Button as='a' target='_blank' href={`https://opensea.io/${user.accounts[0].account}`} variant="outline-secondary" >
                                    <Image src={Images.opensea} style={{ width: '1.2rem', height: '1.2rem', verticalAlign: '-0.25rem' }}></Image>
                                </Button>
                                <Button onClick={() => {
                                    setShowDropMenu(!showDropMenu)
                                }} variant="outline-secondary" style={{ position: 'relative' }}>
                                    <FontAwesomeIcon icon={['fas', 'share-alt']} color={color} />
                                    {showDropMenu &&
                                        <div className={style.mask} onClick={() => {
                                            setShowDropMenu(false)
                                        }}></div>
                                    }
                                    <Dropdown.Menu show={showDropMenu} style={{ right: 0, marginTop: '0.5rem' }}>
                                        {user?.username &&
                                            <Dropdown.Item >
                                                <CopyToClipboard text={`${window.location.origin}/${user.username}`}
                                                    onCopy={() => {
                                                        toast.info('Link copied!')
                                                        setShowDropMenu(false)
                                                    }}>
                                                    <div className={style.shareItem}>
                                                        <Image src={Images.logo} roundedCircle></Image>
                                                        <span>Copy Link</span>
                                                    </div>
                                                </CopyToClipboard>
                                            </Dropdown.Item>
                                        }
                                        <Dropdown.Divider />
                                        <Dropdown.Item as='a' target={'_blank'} href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/${user?.username}`}>
                                            <div className={style.shareItem}>
                                                <FontAwesomeIcon icon={["fab", "facebook"]} size={'2x'} color='#1977F2' />
                                                <span>Share on Facebook</span>
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item as='a' target={'_blank'} href={`https://twitter.com/intent/tweet?text=${encodeURI(`Check out this account on NiftyNet&url=${window.location.origin}/${user?.username}&via=NiftyNet`)}`}>
                                            <div className={style.shareItem}>
                                                <FontAwesomeIcon icon={["fab", "twitter"]} size={'2x'} color='#1C9CF0' />
                                                <span>Share to Twitter</span>
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Button>
                            </ButtonGroup>

                        </div>
                    }
                    {links && links.filter(v => {
                        if (v.status) {
                            if (v.type === 'header') {
                                return v.title.length > 0
                            } else {
                                return v.title.length > 0 && (v.link && v.link.length > 0)
                            }
                        } else {
                            return false
                        }
                    }).map(v => {
                        if (v.type === 'link') {
                            const protocol = v.link ? (/^http[s]?:\/{2}/.test(v.link) ? '' : 'https://') : ''
                            const url = protocol + (v.link || '')
                            return <a
                                href={url}
                                target={'_blank'}
                                rel="noreferrer"
                                className={btnClassname.join(' ')}
                                key={v.id}
                                style={btnStyle}
                            >
                                {v.title}
                            </a>
                        } else {
                            return <h3 className={style.linkTitle} key={v.id}>{v.title}</h3>
                        }
                    })}
                </Container>

                {token_count > 0 && <h3>My Collection</h3>}

                <GridBootstrap>
                    {topTokens.filter(v => {
                        return v !== null
                    }).map((v, i) => (
                        <Item data={v} key={v.id} fontColor={color} borderColor={color} viewCollection onClick={() => {
                            setTokenDetail(v)
                        }} />
                    ))}
                    {nfts && nfts.filter(v => {
                        return topTokens.findIndex(t => {
                            return (t && (t.id === v.id))
                        }) < 0
                    }).map((v, i) => (
                        <Item data={v} key={v.id} fontColor={color} borderColor={color} viewCollection onClick={() => {
                            setTokenDetail(v)
                        }} />
                    ))}
                </GridBootstrap>

                {showLoadBtn &&
                    <div>
                        {!enableLoadTokens && <Container fluid>
                            <div className={style.enableLoadTokens} onClick={() => {
                                setEnableLoadTokens(true)
                            }}>
                                <span>View All NFTs</span>
                                <FontAwesomeIcon icon={['fal', 'arrow-down']} />
                            </div>
                        </Container>}

                        {enableLoadTokens && <div>
                            <Loading show={pageLoading} background="transparent"></Loading>
                            <ScrollLoading onReachBottom={() => {
                                if (fullLoad || pageLoading) {
                                    return
                                }
                                setPage(preState => {
                                    return preState + 1
                                })
                            }} />
                        </div>}
                    </div>
                }

                <Modal show={tokenDetail !== null} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body style={{ padding: 0 }} className='fontFamilyWrapper'>
                        {tokenDetail !== null && <div className={style.nftDetail}>
                            <div className={style.image} onClick={() => {
                                setViewerImgs([{
                                    src: tokenDetail.image_url.length > 0 ? tokenDetail.image_url : tokenDetail.community.image_url,
                                    alt: ''
                                }])
                            }}>
                                <Image src={tokenDetail.image_url.length > 0 ? tokenDetail.image_url : tokenDetail.community.image_url}></Image>
                            </div>
                            <Container>
                                <p className={style.nftName}>
                                    {tokenDetail.name}
                                </p>
                                <div className={style.row}>
                                    <p className={style.title}>
                                        Creator
                                    </p>
                                    <a target={'_blank'} rel="noreferrer" href={`https://opensea.io/${tokenDetail.creator_address}`}>
                                        <div className={style.userRow}>
                                            <Image src={tokenDetail.creator_avatar} roundedCircle></Image>
                                            <div className={style.center}>
                                                <p className={style.subtitle}>
                                                    {tokenDetail.creator_name}
                                                </p>
                                                <p className={[style.text, style.ellipsis].join(' ')}>
                                                    Account:  {tokenDetail.creator_address}
                                                </p>
                                            </div>
                                            <FontAwesomeIcon icon={['fas', 'external-link']} color='#536471' />
                                        </div>
                                    </a>
                                </div>
                                <div className={style.row}>
                                    <p className={style.title}>
                                        Owner
                                    </p>
                                    <a target={'_blank'} rel="noreferrer" href={`https://opensea.io/${tokenDetail.owner_address}`}>
                                        <div className={style.userRow}>
                                            <Image src={tokenDetail.owner_avatar} roundedCircle></Image>
                                            <div className={style.center}>
                                                <p className={style.subtitle}>
                                                    {tokenDetail.owner_name}
                                                </p>
                                                <p className={[style.text, style.ellipsis].join(' ')}>
                                                    Account:  {tokenDetail.owner_address}
                                                </p>
                                            </div>
                                            <FontAwesomeIcon icon={['fas', 'external-link']} color='#536471' />
                                        </div>
                                    </a>
                                </div>
                                {tokenDetail.description &&
                                    <div className={style.row}>
                                        <p className={style.title}>
                                            Description
                                        </p>
                                        <p className={[style.text, style.description].join(' ')}>
                                            <ReactMarkdown>{tokenDetail.description}</ReactMarkdown>
                                        </p>
                                    </div>
                                }
                                <div className={style.row}>
                                    <p className={style.title}>
                                        Collection
                                    </p>
                                    <a target={'_blank'} rel="noreferrer" href={`https://opensea.io/collection/${tokenDetail.community.slug.replaceAll(' ', '-').toLocaleLowerCase()}`}>
                                        <div className={[style.userRow, 'mb-2'].join(' ')}>
                                            <Image src={tokenDetail.community.image_url} rounded></Image>
                                            <div className={style.center}>
                                                <p className={style.subtitle}>
                                                    {tokenDetail.community.name}
                                                </p>
                                                {tokenDetail.community.safelist_request_status === 'verified' &&
                                                    <p className={[style.text].join(' ')}>
                                                        Verified collection by OpenSea
                                                    </p>
                                                }
                                            </div>
                                            <FontAwesomeIcon icon={['fas', 'external-link']} color='#536471' />
                                        </div>
                                    </a>
                                    {tokenDetail.community.description &&
                                        <p className={[style.text, style.description].join(' ')}>
                                            <ReactMarkdown>{tokenDetail.community.description}</ReactMarkdown>
                                        </p>
                                    }
                                </div>
                                {/* <div className={style.row}>
                                    <p className={style.title}>
                                        Properties
                                    </p>
                                    <Grid columns={2}>
                                        <div className={style.property}>
                                            <p className={style.key}>BACKGROUND</p>
                                            <p className={style.val}>{tokenDetail.background_color || 'NULL'}</p>
                                        </div>
                                    </Grid>
                                </div> */}
                                <div className={style.row}>
                                    <p className={style.title}>
                                        NFT Details
                                    </p>
                                    <Grid columns={2}>
                                        <div className={style.property}>
                                            <a target={'_blank'} rel="noreferrer" href={tokenDetail.metadata.permalink}>
                                                <div className={style.ContractAddress}>
                                                    <p className={style.key}>Contract Address</p>
                                                    <FontAwesomeIcon icon={['fas', 'external-link']} color='#536471' />
                                                </div>
                                                <p className={style.val}>{tokenDetail.address}</p>
                                            </a>
                                        </div>

                                        <div className={style.property}>
                                            <p className={style.key}>Token ID</p>
                                            <p className={style.val}>{tokenDetail.token_id}</p>
                                        </div>
                                        <div className={style.property}>
                                            <p className={style.key}>Blockchain</p>
                                            <p className={style.val}>{tokenDetail.symbol}</p>
                                        </div>
                                        <div className={style.property}>
                                            <p className={style.key}>Token Standard</p>
                                            <p className={style.val}>{tokenDetail.schema_name}</p>
                                        </div>
                                    </Grid>
                                </div>
                            </Container>
                        </div>}

                    </Modal.Body>
                </Modal>
            </div>

            <Viewer
                visible={viewerImgs.length > 0}
                onClose={() => { setViewerImgs([]); }}
                images={viewerImgs}
                zIndex={10000}
            />

            <a href='/' className={style.logo} style={{ color }}>
                <Image src={Images.logo}></Image>
                <span>{Config.siteName}</span>
            </a>
        </div>
    </div >
}
Link.defaultProps = defaultProps
export default Link

