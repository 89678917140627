import { handleActions } from 'redux-actions';
import types from '../actions/types';

const initialState = {
  id: '',
  username: '',
  display_name: '',
  avatar: '',
  bio: '',
};

const user = handleActions({
  [types.SET_USER]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      ...payload
    };
  },
}, initialState);

export default user;
