import { firebaseApp } from '../contexts/AuthContext'
import { getStorage, ref, uploadBytes, connectStorageEmulator } from '@firebase/storage'
import { getFirestore as GFS, connectFirestoreEmulator } from 'firebase/firestore'
import isDev from './helpers';

interface UploadResult {
    metadata: object,
    ref: object
}

export const getFireStorage = (bucketUrl: string = '') => {
    const storage = getStorage(firebaseApp, bucketUrl)
    // if (isDev()) {
    //     connectStorageEmulator(storage, 'localhost', 9199)
    // }
    return storage
}


export const uploadFile = (file: File, path: string = '', bucketUrl: string = ''): Promise<UploadResult> => {
    var imageRef = ref(getFireStorage(bucketUrl), path)
    return uploadBytes(imageRef, file).then(snapshot => {
        return snapshot
        // return getDownloadURL(snapshot.ref).then((url) => {
        //     return { url }
        // })
    })
}

export const getFireStore = () => {
    const db = GFS();
    return db;
}