import { handleActions } from 'redux-actions';
import types from '../actions/types';

const initialState = {
    list: [],
    loading: false,
};

const user = handleActions({
    [types.SET_NFTS]: (state, action) => {
        const { payload } = action;
        return {
            ...state,
            list: payload
        };
    },
    [types.SET_NFTS_STATE]: (state, action) => {
        const { payload } = action;
        return {
            ...state,
            loading: payload
        };
    },
}, initialState);

export default user;
