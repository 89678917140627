import "./App.css";
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Admin from "./views/admin/Index";
import isDev, { HOSTNAME } from "./utils/helpers";
import Error404 from "./Error404";
import store from './redux/store';
//utils
import './utils/fontawesome'
import './utils/axios'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//views
import Link from './views/link/Index'
import { AuthProvider, } from "./contexts/AuthContext";

// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Config from "./utils/config";
import Collection from "./views/collection/Index";

import { AliveScope } from 'react-activation'
import KeepAlive from 'react-activation'

export default function App() {
  // if (!isDev() && window.location.hostname != HOSTNAME) {
  //   window.location.href = "https://" + HOSTNAME;
  // }
  return (
    <HelmetProvider>
      <Provider store={store}>
        <AuthProvider>
          <ToastContainer />
          <Helmet>
            <link rel="stylesheet" href={'https://fonts.googleapis.com/css2?' + Config.Fonts.map(f => {
              return 'family=' + f.replaceAll(' ', '+')
            }).join('&')} />
            <title>{Config.siteName}</title>
          </Helmet>
          <Router>
            <AliveScope>
              <Switch>
                <Route path="/admin">
                  <div>
                    <Helmet>
                      <title>{Config.siteName} Admin</title>
                    </Helmet>
                    <Admin />
                  </div>
                </Route>
                <Route path="/collection/:name" render={props => (
                  <KeepAlive name="collection" id={`collection_${props.match.params.name}`}>
                    <Collection {...props} />
                  </KeepAlive>
                )} />
                <Route path="/:name" render={props => (
                  <KeepAlive name="userprofile" id={`userprofile_${props.match.params.name}`}>
                    <Link {...props} />
                  </KeepAlive>
                )} />
                <Route exact path="/">
                  <Redirect to={'/admin/login'} />
                </Route>
                <Route path="/*">
                  <Error404 />
                </Route>
              </Switch>
            </AliveScope>
          </Router>
        </AuthProvider>
      </Provider>
    </HelmetProvider>
  );
}