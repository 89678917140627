import style from './index.module.scss'

const defaultProps = {
    backgroundColor: '#8D1EBC',
    type: 'label'
};
type IProps = {
    type: 'label' | 'dot',
    label: string | number,
    backgroundColor: string
} & typeof defaultProps;

const Badge = function (props: IProps) {
    const show = typeof props.label == 'number' ? props.label > 0 : props.label.length > 0
    let view = null
    if (show) {
        if (props.type === 'label') {
            view = <div className={style.badge} style={{
                backgroundColor: props.backgroundColor
            }}>
                <span>{props.label}</span>
            </div>
        } else {
            view = <div className={[style.badge, style.dot].join(' ')} style={{
                backgroundColor: props.backgroundColor
            }}>
            </div>
        }
    }

    return view;
}

Badge.defaultProps = defaultProps;
export default Badge