import { useEffect, useState } from 'react'
import GridItem from '../../../components/grid/Item'
import GridBootstrap from '../../../components/grid/Index'
import { useAuth } from '../../../contexts/AuthContext'
import { Itoken } from '../../../types'
import { getTokens } from '../../../api/tokens'
import ScrollLoading from '../../../components/scrollLoading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import style from './TokensHide.module.scss'

const defaultProps = {

};
type IProps = {
    hides: Array<any>,
    trigger?: string,
    onChange?: Function
} & typeof defaultProps;


const TokensHide = (props: IProps) => {
    const { onChange } = props
    const { user } = useAuth()

    const [hides, setHides] = useState<number[]>(props.hides)

    const [pageTokens, setPageTokens] = useState<Itoken[]>([])
    const [allTokens, setAllTokens] = useState<Itoken[]>([])


    const [loading, setLoading] = useState<boolean>(false)
    const [isEmpty, setIsEmpty] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1)

    const limit = 20

    useEffect(() => {
        setHides(props.hides)
    }, [props.hides])

    useEffect(() => {
        if (allTokens.length === 0 && user?.uid) {
            setLoading(true)
            getTokens(user?.uid, 0, 0, 0).then(res => {
                if (res.length === 0) {
                    setIsEmpty(true)
                } else {
                    setAllTokens(res)
                }
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [allTokens.length, user?.uid])

    useEffect(() => {
        if (allTokens.length > 0) {
            setPageTokens(allTokens.slice(0, page * limit))
        }
    }, [page, allTokens])

    const view = <div>
        <GridBootstrap>
            {pageTokens.map((v: any, i: any) => (
                <GridItem data={v} key={v.id} onClick={() => {
                    if (onChange) {
                        onChange(v.id)
                    }
                }} badge={<div>
                    {(hides && hides.includes(v.id)) && <div className={style.hideMaxk}>
                        <FontAwesomeIcon icon={['fas', 'eye-slash']} color="#fff" />
                    </div>}
                </div>} />
            ))}
        </GridBootstrap>
        <ScrollLoading onReachBottom={() => {
            setPage((preState) => {
                return preState + 1
            })
        }}
            isLoading={loading}
            isEmpty={isEmpty}
            triggerID={props.trigger}
        />
    </div>

    return allTokens ? view : null
}

TokensHide.defaultProps = defaultProps;
export default TokensHide

