import { useState } from 'react'
import { Dropdown, Image } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { useHistory } from 'react-router-dom'
import { NiftyAuthenticationState, useAuth } from '../../contexts/AuthContext'
import Images from '../../images'
import { isEmpty } from '../../utils/helpers'
import Avatar from '../avatar'
import Hexagon from '../avatar/Hexagon'
import style from './Index.module.scss'

const Header = () => {
    const { authState, login, logout } = useAuth()
    const [showDropdown, setShowDropdown] = useState<boolean>(false)
    const [showAuthenticatedDropdown, setShowAuthenticatedDropdown] = useState<boolean>(false)
    const userInfo = useSelector((state: any) => state.user)

    const history = useHistory()

    const handleLogin = (type: string = '') => {
        setShowDropdown(false)
        login(type).then((res: any) => {
            if (res) {
                history && history.push('/admin')
            }
        })
    }

    const avatar = isEmpty(userInfo.avatar) ? Images.default_avatar : userInfo.avatar

    return <div className={style.header}>
        <div className={style.logo}>
            <a href="/">
                <Image src={Images.logo}></Image>
                <span className='fontArchivRegular'>NIFTYNET</span>
            </a>
        </div>
        {authState === NiftyAuthenticationState.AUTHENTICATED ? <div className={style.haslogin}>
            <div onClick={() => {
                setShowAuthenticatedDropdown(true)
            }}>
                {avatar.includes('source=nft') ?
                    <Hexagon src={avatar} size='3rem' borderColor='#333' />
                    :
                    <Avatar src={avatar} size='3rem' roundedCircle />
                }
            </div>
            <Dropdown.Menu show={showAuthenticatedDropdown} style={{ right: 0, marginTop: '0.2rem' }} onClick={() => {
                setShowAuthenticatedDropdown(false)
            }}>
                <Dropdown.Header>NiftyNet</Dropdown.Header>
                <LinkContainer to={`/admin/profile`}>
                    <Dropdown.Item >My Account</Dropdown.Item>
                </LinkContainer>
                <div>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
                </div>
            </Dropdown.Menu>
            {showAuthenticatedDropdown &&
                <div className='mask' onClick={() => {
                    setShowAuthenticatedDropdown(false)
                }}></div>
            }
        </div> : <div className={style.notlogin}>
            <div className={style.btn} onClick={() => {
                setShowDropdown(!showDropdown)
            }}>Connect Wallet</div>
            <Dropdown.Menu show={showDropdown} style={{ right: 0, top: 'calc( 100% + 5px )' }}>
                <Dropdown.Item onClick={() => handleLogin()}>
                    <div className={style.dropItem}>
                        <Image src={Images.metamask} />
                        Log in with MetaMask
                    </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => handleLogin('WalletConnect')}>
                    <div className={style.dropItem}>
                        <Image src={Images.walletconnect} />
                        Log in with WalletConnect
                    </div>
                </Dropdown.Item>
            </Dropdown.Menu>
            {showDropdown &&
                <div className='mask' onClick={() => {
                    setShowDropdown(false)
                }}></div>
            }
        </div>}
    </div>
}

export default Header