import Images from "../images"

type Tconfig = {
    social_links: {
        [key: string]: any
    },
    DefaultFont: string
    Fonts: string[],
    DisabledUsername: string[],
    siteName: string,
    signMessage: string,
    homeExamples: { name: string, image: string, displayName: string }[],
    [key: string]: any
}

const Config: Tconfig = {
    siteName: 'NiftyNet',
    signMessage: "Hi, it’s the NiftyNet team! \n\nSigning this message proves you \nown this wallet and nothing else. \n\nIt’s free and doesn’t grant transaction rights. \n\nWelcome to NiftyNet!",
    social_links: {
        'Twitter': {
            name: 'Twitter',
            value: '',
            icon: ['fab', 'twitter'],
            host: 'twitter.com/',
            placeholder: 'YourTwitterHandle'
        },
        'Discord': {
            name: 'Discord',
            value: '',
            icon: ['fab', 'discord'],
            host: 'discord.com/users/',
            placeholder: 'YourDiscordUserId'
        },
        'Facebook': {
            name: 'Facebook',
            value: '',
            icon: ['fab', 'facebook'],
            host: 'facebook.com/',
            placeholder: 'YourFacebookHandle'
        },
        'LinkedIn': {
            name: 'LinkedIn',
            value: '',
            icon: ['fab', 'linkedin'],
            host: 'linkedin.com/in/',
            placeholder: 'YourLinkedInHandle'
        },
        'Instagram': {
            name: 'Instagram',
            value: '',
            icon: ['fab', 'instagram-square'],
            host: 'instagram.com/',
            placeholder: 'YourInstagramHandle'
        }
    },
    DefaultFont: 'Roboto',
    Fonts: [
        'Barlow',
        'Capriola',
        'DM Sans',
        'Gothic A1',
        'IBM Plex Sans',
        'Inter',
        'Karla',
        'Lato',
        'Montserrat',
        'Oswald',
        'Poppins',
        'Quicksand',
        'Roboto',
        'Rubik',
        'Work Sans',
        'Courgette'
    ],
    DisabledUsername: [
        'admin',
        'niftynet',
        'login'
    ],
    homeExamples: [
        {
            name: 'datadiver',
            image: Images.example_niftynet,
            displayName: 'datadiver'
        },
        {
            name: '0xmatias',
            image: Images.example_0xmatias,
            displayName: 'Matias Molina'
        },
        {
            name: 'mouton.eth',
            image: Images.example_moutoneth,
            displayName: 'mouton.eth'
        }
    ]
}


export default Config