
import axios from "axios"
import { Icommunity, Iuser } from "../types"


export const getCommunityById = async (id: number) => {
    const response = await axios.get(`/api/community/${id}.json`, {
        params: { type: 'id' }
    })
    return response.data as Icommunity
}

export const getCommunityBySlug = async (slug: string) => {
    const response = await axios.get(`/api/community/${slug}.json`, {
        params: { type: 'slug' }
    })
    return response.data as Icommunity
}

export const getCommunityUsersById = async (id: number, page: number) => {
    const response = await axios.get(`/api/community/users.json`, {
        params: {
            id,
            page
        }
    })
    return response.data as Iuser[]
}

export const getCommunityUsersBySlug = async (slug: string, page: number) => {
    const response = await axios.get(`/api/community/users.json`, {
        params: {
            slug,
            page
        }
    })
    return response.data as Iuser[]
}
