import store from './store';
import actions from './actions/creator';

export async function setUserInfo(payload) {
    await store.dispatch(actions.setUser(payload))
}

export async function logout() {
    await store.dispatch(actions.setUser(null))
}
