import Image from 'react-bootstrap/Image'
import style from './index.module.scss'
import Badge from '../badge';

const defaultProps = {
    borderColor: '#0086db',
    border: 'none',
    size: '2.5rem'
};
type IProps = {
    src?: string,
    border: 'double' | 'none' | string,
    borderColor: string,
    size: string,
    rounded?: boolean,
    roundedCircle?: boolean,
    thumbnail?: boolean,
    margin?: string,
    badge?: string | number,
    badgeType?: 'label' | 'dot',
    className?: string
} & typeof defaultProps;

const Avatar = function (props: IProps) {
    const { src, rounded, roundedCircle, thumbnail, className, border, borderColor, badge, size, badgeType } = props

    let borderStr = 'none'
    if (border === 'double') {
        borderStr = `3px solid ${borderColor}`
    } else if (border !== 'none') {
        borderStr = `${border} solid ${borderColor}`
    }

    return (
        <div className={[style.avatar, className].join(' ')} style={{ margin: props.margin }}>
            {badge !== undefined && <div className={style.badge} style={{
                left: roundedCircle ? `calc(${size} - 1.4rem)` : `calc(${size} - 0.7rem)`,
                top: roundedCircle ? '0' : '-0.7rem'
            }}><Badge label={badge} type={badgeType} /></div>}
            <div style={{
                padding: border === 'double' ? '1px' : 0,
                backgroundColor: 'white',
                borderRadius: props.roundedCircle ? '100%' : '0.25rem',
                border: borderStr
            }}>
                <Image {...{ src, rounded, roundedCircle, thumbnail }} style={{ width: size, height: size }}></Image>
            </div>
        </div>
    );
}

Avatar.defaultProps = defaultProps;
export default Avatar