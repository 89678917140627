import style from './Hexagon.module.scss'

const defaultProps = {
    size: '100px',
    borderColor: '#ffffff'
};
type IProps = {
    src: string,
    size: string,
    borderColor: string
} & typeof defaultProps;

const Hexagon = (props: IProps) => {
    const { src, size, borderColor } = props

    return <div className={style.userAvatar}>
        <div className={style.outer} style={{ width: size, height: size, backgroundColor: borderColor }}>
            <div className={style.image}>
                <img src={src} alt="" />
            </div>
        </div>
    </div>
}

Hexagon.defaultProps = defaultProps;
export default Hexagon