import { handleActions } from 'redux-actions';
import types from '../actions/types';

const initialState = {
    is_syncing: false,
};

const sync = handleActions({
    [types.SET_SYNC_STATUS]: (state, action) => {
        const { payload } = action;
        return {
            ...state,
            is_syncing: payload
        };
    },
}, initialState);

export default sync;
