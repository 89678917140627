import { useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap'
import { checkSync } from '../../../api/sync';
import { setSyncStatus } from '../../../redux/sync';
import { Iuser } from '../../../types';
import style from './Index.module.scss'

export default function Tab() {
    const matchTab = (key: string) => {
        const regex = new RegExp(`^${key}`);
        return regex.test(window.location.pathname)
    }
    const is_syncing = useSelector((state: any) => state.sync.is_syncing as boolean)
    const userInfo = useSelector((state: any) => state.user as Iuser)

    useEffect(()=>{
        checkSync().then((res: any) => {
            setSyncStatus(res.status)
        })
    }, [])

    useEffect(() => {
        let timer: any = null
        if (is_syncing) {
            timer = setInterval(() => {
                checkSync().then((res: any) => {
                    setSyncStatus(res.status)
                })
            }, 5000);
        }
        return () => {
            timer && clearInterval(timer)
        }
    }, [is_syncing])

    return <div className={style.tab}>
        <Container>
            <div className={style.container}>
                
                <LinkContainer to={`/admin/profile`}>
                    <div className={[style.item, matchTab('/admin/profile') ? style.active : ''].join(' ')}>
                        Profile
                    </div>
                </LinkContainer>
                <LinkContainer to={`/admin/links`}>
                    <div className={[style.item, matchTab('/admin/links') ? style.active : ''].join(' ')}>
                        Links
                    </div>
                </LinkContainer>
                <LinkContainer to={`/admin/appearance`}>
                    <div className={[style.item, matchTab('/admin/appearance') ? style.active : ''].join(' ')}>
                        Appearance
                    </div>
                </LinkContainer>
                <LinkContainer to={`/admin/sync`}>
                    <div className={[style.item, matchTab('/admin/sync') ? style.active : ''].join(' ')}>
                        Sync
                        {is_syncing && <div className='ms-2'><Spinner animation="border" variant="primary" size='sm' /></div>}
                    </div>
                </LinkContainer>
            </div>
        </Container>
    </div>
}
