import style from './index.module.scss'
import { Spinner } from "react-bootstrap";

const defaultProps = {
    fill: true,
    show: false,
    opacity: 0.7,
    background: 'rgba(255, 255, 255, 0.8)',
    zIndex: 100
};
type IProps = {
    show: boolean,
    fill?: boolean,
    fullScreen?: boolean,
    background?: string,
    zIndex: number
} & typeof defaultProps;

function Loading(props: IProps) {
    const { fill, fullScreen, show, background, zIndex } = props
    return <div>
        {show && <div className={[style.loading, fullScreen ? style.fullScreen : '', fill ? style.fill : ''].join(' ')} style={{
            backgroundColor: background,
            zIndex: zIndex
        }}>
            <Spinner animation="border" variant="primary" />
        </div>}
    </div>
}


Loading.defaultProps = defaultProps;
export default Loading