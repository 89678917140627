import style from './Index.module.scss'
import { Image, Col, Container, Row } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap'
import Images from '../../../images';
import { useAuth, NiftyAuthenticationState } from '../../../contexts/AuthContext';
import { useHistory } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Hexagon from '../../../components/avatar/Hexagon';
import Config from '../../../utils/config';
import Header from '../../../components/header/Index';

const Login = () => {
    const history = useHistory();
    const { authState, login } = useAuth()

    const handleLogin = (type: string = '') => {
        login(type).then((res: any) => {
            if (res) {
                history && history.push('/admin')
            }
        })
    }

    return <div>
        <Header />
        <div className='fontArchivRegular'>
            <div className={style.part1}>
                <h1>ONE WALLET, ONE PROFILE, ONE LINK </h1>
                <div className='d-lg-none d-xl-none d-xl-block'>
                    <a href="/datadiver" onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        history.push('/datadiver')
                        return false
                    }}>
                        <div className={style.left}>
                            <div className={style.avatar}>
                                <Hexagon borderColor='#000' size='6rem' src='https://lh3.googleusercontent.com/WUcpztiwN9NwBnKps1TTkG7wgHgXATYqkKjl6WGV0DoZ1g36sXmV1QIgExq19HjcbucDbz0SCooU444UD-Lts9qxWETBPDYbHnCl=s0' />
                            </div>
                            <p>DATADIVER <FontAwesomeIcon icon={['fas', 'external-link']} /> </p>
                        </div>
                    </a>
                </div>
                <div className={style.loginPanel}>
                    <div className='d-none d-lg-block'>
                        <a href="/datadiver" onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            history.push('/datadiver')
                            return false
                        }}>
                            <div className={style.left}>
                                <div className={style.avatar}>
                                    <Hexagon borderColor='#000' size='6rem' src='https://lh3.googleusercontent.com/WUcpztiwN9NwBnKps1TTkG7wgHgXATYqkKjl6WGV0DoZ1g36sXmV1QIgExq19HjcbucDbz0SCooU444UD-Lts9qxWETBPDYbHnCl=s0' />
                                </div>
                                <p>DATADIVER <FontAwesomeIcon icon={['fas', 'external-link']} /> </p>
                            </div>
                        </a>
                    </div>
                    <div className={style.right}>
                        <a href='https://twitter.com/TheDataDiver' target={'_blank'} rel="noreferrer">
                            <p className={style.name}>@THEDATADIVER</p>
                        </a>
                        <p>CREATE YOUR WEB3 PROFILE,</p>
                        <p>SHOWCASE YOUR NFTS,</p>
                        <p>SOCIAL HANDLES</p>
                        <p>AND LINKS</p>
                        {authState === NiftyAuthenticationState.AUTHENTICATED ?
                            <LinkContainer to={'/admin'}>
                                <div className={style.Btn}>
                                    Go To Admin
                                </div>
                            </LinkContainer>
                            :
                            <div>
                                <div className={style.Btn} onClick={() => {
                                    handleLogin()
                                }}>
                                    <Image src={Images.metamask} />
                                    Login with MetaMask
                                </div>
                                <div className={style.Btn} onClick={() => {
                                    handleLogin('WalletConnect')
                                }}>
                                    <Image src={Images.walletconnect} />
                                    Login with WalletConnect
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={style.part3}>
                <h1>FEATURED PROFILES</h1>
                <div className={style.maxW}>
                    <Container>
                        <Row className="justify-content-center">
                            {Config.homeExamples.map(v => <Col key={v.name} xs={12} sm={6} md={6} lg={3} xl={3} xxl={3} >
                                <a href={`/${v.name}`} onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    history.push(`/${v.name}`)
                                    return false
                                }}>
                                    <div className={style.hotuser}>
                                        <Image src={v.image} />
                                        <p>{v.displayName} <FontAwesomeIcon icon={['fas', 'external-link']} /> </p>
                                    </div>
                                </a>
                            </Col>)}
                        </Row>
                    </Container>
                </div>
            </div>
            <div className={style.part2}>
                <h1>Features</h1>
                <div className={style.maxW}>
                    <Container>
                        <Row>
                            <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4} >
                                <div className={style.featureItem}>
                                    <div className={style.icon}>
                                        <FontAwesomeIcon icon={['fas', 'globe']} size="4x" />
                                    </div>
                                    <p className={style.name}>Use it anywhere</p>
                                    <p className={style.desc}>Take your NiftyNet profile wherever you go, be it Discord, Twitter or Instagram.</p>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4} >
                                <div className={style.featureItem}>
                                    <div className={style.icon}>
                                        <FontAwesomeIcon icon={['fab', 'ethereum']} size="4x" />
                                    </div>
                                    <p className={style.name}>Web3 Integration</p>
                                    <p className={style.desc}>Showcase your NFTs alongside your socials and links.</p>
                                </div>
                            </Col>
                            <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4} >
                                <div className={style.featureItem}>
                                    <div className={style.icon}>
                                        <FontAwesomeIcon icon={['fas', 'chart-network']} size="4x" />
                                    </div>
                                    <p className={style.name}>Connect with Others</p>
                                    <p className={style.desc}>Easily find the social handles of other users who share mutual collections. (In progress)</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* <div className={style.center}>
                <LinkContainer to={'/admin/login'}>
                    <div className={style.Btn}>Get Started For Free</div>
                </LinkContainer>
            </div> */}
            </div>
        </div>
    </div >
}

export default Login