
import axios from 'axios';
import { firebaseAuth } from "../contexts/AuthContext";
import { HOST_BACKEND } from './helpers';

axios.defaults.timeout = 30000;

// axios.defaults.validateStatus = function (status) {
//     return status >= 200 && status <= 500;
// };

axios.defaults.withCredentials = false;
axios.defaults.baseURL = HOST_BACKEND

axios.interceptors.request.use(async (config: any) => {
    if (!config.headers['Authorization'] && firebaseAuth?.currentUser) {
        const token = await firebaseAuth?.currentUser.getIdToken()
        config.headers['Authorization'] = token || ''
    }

    return config
}, (error: any) => {
    return Promise.reject(error)
});

axios.interceptors.response.use(async (res: any) => {
    return res;
}, async (error: any) => {
    return Promise.reject(error);
})

export default axios;
