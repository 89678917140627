import LinkCompument from "../../components/link/Link"
import { useEffect, useState } from "react"
import Loading from "../../components/loading/index";
import { Iuser } from "../../types";
import { getUserByName, } from "../../api/users";
import { Redirect } from "react-router-dom";


const Link = (props: { match: { params: { name: any } } }) => {
    const [user, setUser] = useState<Iuser>()
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        if (props.match.params.name) {
            getUserByName(props.match.params.name).then(res => {
                setUser(res)
                setLoading(false)
            })
        }
    }, [props.match.params.name])


    const background = user?.config ? user.config.background : 'none'

    return <div style={{ background: background }}>
        {loading ? <Loading show fullScreen /> : <div>
            {user !== null ? <LinkCompument user={user} /> : <Redirect to={'/'} />}
        </div>}
    </div>
}

export default Link

