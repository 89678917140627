import { useState, useEffect, useRef, CSSProperties, useCallback } from "react"
import { v4 as uuidv4 } from 'uuid';
import { uploadFile } from "../../utils/firebase";

type IuploadStatus = -1 | 0 | 1 | 2 //-1:error 0:wait for upload 1:uploading 2:success

export interface IuploadFile {
    metadata?: any,
    file?: File,
    key: string,
    status: IuploadStatus,
    error?: string
}

const defaultProps = {

};

type IProps = {
    onChange?: Function,
    children?: React.ReactElement,
    style?: CSSProperties
    path?: string
} & typeof defaultProps;

const Upload = function (props: IProps) {
    const { onChange, children, path } = props

    const [file, setFile] = useState<IuploadFile>()

    const inputFileRef = useRef<HTMLInputElement>(null)

    const handleUpload = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click()
        }
    }

    const handleFileChnage = useCallback(() => {
        if (inputFileRef.current) {
            const selectedFiles = inputFileRef.current.files

            if (selectedFiles) {
                setFile({
                    file: selectedFiles[0],
                    key: uuidv4(),
                    status: 0,
                })
            }
            inputFileRef.current.value = ''
        }
    }, [inputFileRef])

    useEffect(() => {
        if (file?.status === 0) {
            if (file.file) {
                if (file.file.size > 2 * 1024 * 1024) {
                    setFile({
                        ...file,
                        status: -1,
                        error: 'A maximum of 2M images can be uploaded'
                    })
                } else {
                    setFile({
                        ...file,
                        status: 1,
                    })
                    // uploadFile(file.file, (path ? `${path}/` : '') + file.key + file.file.name.split('.')[1], 'niftynet-62794-images').then(({ metadata }) => {
                    uploadFile(file.file, (path ? `${path}/` : '') + file.key, 'niftynet-images').then(({ metadata }) => {
                        setFile({
                            ...file,
                            metadata,
                            status: 2
                        })
                    }).catch(err => {
                        setFile({
                            ...file,
                            status: -1,
                            error: err
                        })
                    })
                }
            }
        }
        onChange && onChange(file)
    }, [file])

    return <div>
        <div style={props.style} onClick={handleUpload}>
            {children}
            <input type="file" accept="image/*" style={{ display: 'none' }} ref={inputFileRef} onChange={handleFileChnage} />
        </div>
    </div>

}

export default Upload
