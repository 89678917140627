const types = {
  //user
  SET_USER: 'SET_USER',

  SET_LINKS: 'SET_LINKS',
  UPDATE_LINK: 'UPDATE_LINK',
  DELETE_LINK: 'DELETE_LINK',

  //NFT
  SET_NFTS: 'SET_NFTS',
  SET_NFTS_STATE: 'SET_NFTS_STATE',

  //SYNC
  SET_SYNC_STATUS: 'SET_SYNC_STATUS'
};

export default types;
