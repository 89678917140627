import React from 'react';
import { Container, Row } from 'react-bootstrap';

const defaultProps = {

};
type IProps = {
    children: any
} & typeof defaultProps;


const Index = (props: IProps) => {
    return (
        <Container fluid>
            <Row>
                {props.children}
            </Row>
        </Container>
    );
}

Index.defaultProps = defaultProps;
export default Index
