import { handleActions } from 'redux-actions';
import types from '../actions/types';

const initialState = {
    links: []
};

const user = handleActions({
    [types.SET_LINKS]: (state, action) => {
        const { payload } = action;
        return {
            links: payload
        };
    },
    [types.UPDATE_LINK]: (state, action) => {
        const { payload } = action;
        const index = state.links.findIndex(v => {
            return v.id === payload.id
        })
        state.links[index] = payload
        return {
            links: [...state.links]
        };
    },
    [types.DELETE_LINK]: (state, action) => {
        const { payload } = action;
        const index = state.links.findIndex(v => {
            return v.id === payload
        })
        state.links.splice(index, 1)
        return {
            links: [...state.links]
        };
    },
}, initialState);

export default user;
