import { useEffect, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import style from './Index.module.scss'
import Moment from 'react-moment';
import { toast } from 'react-toastify'
import { checkSync, sync } from '../../../api/sync'
import { setSyncStatus } from '../../../redux/sync';
import { useSelector } from 'react-redux';
import { Iuser } from '../../../types';

const Sync = () => {
    const [init, setInit] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const userInfo = useSelector((state: any) => state.user as Iuser)
    const [syncData, setSyncData] = useState<{
        last_synced: number
        status: boolean,
        token_count: number
    }>({
        last_synced: 0,
        status: false,
        token_count: 0
    })

    useEffect(() => {
        HandleCheckIsSyncing()
    }, [userInfo])

    const handleSync = () => {
        setLoading(true)
        sync().then((res) => {
            const { status, message } = res
            if (status) {
                setSyncData((pre) => {
                    return {
                        ...pre,
                        status: true
                    }
                })
            } else {
                toast.error(message)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const HandleCheckIsSyncing = () => {
        console.log(111111111111);
        checkSync().then(result => {
            setInit(false)
            setSyncData(result)
        })
    }

    useEffect(() => {
        let timer: any = null
        if (syncData.status) {
            timer = setInterval(() => {
                HandleCheckIsSyncing()
            }, 3000)
        } else {
            timer && clearInterval(timer)
        }


        return () => {
            timer && clearInterval(timer)
        }
    }, [syncData.status])

    useEffect(() => {
        if (!init) {
            setSyncStatus(syncData.status)
        }
    }, [init, syncData.status])

    return <div className={style.container}>
        <h5>Sync Your NFT Collection</h5>
        <div className='mt-5'>
            {syncData.status ? <div>
                <Spinner animation="border" variant="primary" />
                <p className='mt-2'>Synced: {syncData.token_count}</p>
            </div> : <Button disabled={init} onClick={() => {
                handleSync()
            }}>{init ? 'Preparing to sync…' : loading ? <Spinner animation="border" variant="light" size='sm' /> : 'Sync'}</Button>}
        </div>
        {(!syncData.status && syncData.last_synced > 0) && <p className='mt-3'>Last synced:  <Moment fromNow unix>{syncData.last_synced}</Moment></p>}
    </div>
}

export default Sync