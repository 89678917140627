import style from './Links.module.scss'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Collapse, Container, Form, InputGroup, Spinner } from 'react-bootstrap';
import { useAuth } from '../../../contexts/AuthContext';
import { Ilink } from '../../../types';
import { setLinks, updateLink, deleteLink } from '../../../redux/links';
import { addLink, delLink, editLink, getLinks, sortLinks } from '../../../api/links';

const LinkItem = (props: { provided: any, link: Ilink, onDelete: Function }) => {
    const { provided, onDelete } = props
    const [link, setLink] = useState<Ilink>(props.link)
    const [titleEditing, setTitleEditing] = useState<boolean>(false)
    const [footerOpening, setFooterOpening] = useState<boolean>(false)

    const handleEdit = useCallback((value: any, field: string) => {
        const newlink = {
            ...link,
            [field]: value
        }
        editLink(newlink)
        setLink(newlink)
        updateLink(newlink)

    }, [link])

    const handleDelete = useCallback(() => {
        delLink(link.id)
        onDelete(link.id)
        deleteLink(link.id)
    }, [link.id, onDelete])

    const linkIsInvalid = link.title.length > 0 && link.link.length === 0
    const titleIsInvalid = (link.title.length === 0 && link.link.length > 0) || false

    let view = null
    if (link.type === 'link') {
        view = <div className={style.main}>
            <div className={style.linkTitle}>
                <InputGroup hasValidation>
                    <Form.Control type="text" required isInvalid={titleIsInvalid} defaultValue={link.title} placeholder='Title' onBlur={(e) => {
                        setTitleEditing(false)
                        handleEdit(e.target.value, 'title')
                    }} />
                    <Form.Control.Feedback type={titleIsInvalid ? 'invalid' : 'valid'}>
                        Title is required.
                    </Form.Control.Feedback>
                </InputGroup>
            </div>
            <div className={style.link}>
                <InputGroup hasValidation>
                    <Form.Control type="text" required isInvalid={linkIsInvalid} defaultValue={link.link} placeholder='Url' onBlur={(e) => {
                        handleEdit(e.target.value, 'link')
                    }} />
                    <Form.Control.Feedback type={linkIsInvalid ? 'invalid' : 'valid'}>
                        Url is required.
                    </Form.Control.Feedback>
                </InputGroup>
            </div>
        </div>
    } else if (link.type === 'header') {
        view = <div className={style.main}>
            <div className={style.linkHeader}>
                {titleEditing ? <input onBlur={(e) => {
                    setTitleEditing(false)
                    handleEdit(e.target.value, 'title')
                }} autoFocus={true} defaultValue={link.title} style={{ textAlign: 'center' }} /> :
                    <p onClick={() => {
                        setTitleEditing(true)
                    }}>
                        {link.title || 'Headline title'} <FontAwesomeIcon icon={["fas", "pencil"]} color='#bec1c3' className='ms-2' />
                    </p>
                }
            </div>
        </div>
    }

    return <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        style={{ ...provided.draggableProps.style }}
        className={[style.item, link.type === 'header' ? '' : ''].join(' ')}
    >
        <div className={style.body}>
            <div  {...provided.dragHandleProps} className={style.dragHandle}>
                <FontAwesomeIcon icon={["fas", "ellipsis-v"]} />
            </div>
            {view}
            <div className={style.right}>
                <div>
                    <Form.Check
                        type="switch"
                        onChange={(e) => {
                            handleEdit(e.target.checked, 'status')
                        }}
                        defaultChecked={link.status}
                    />
                </div>
                <FontAwesomeIcon icon={["fas", "trash-alt"]} color='#acb5bf' onClick={() => {
                    setFooterOpening(!footerOpening)
                }} />
            </div>
        </div>
        <div>
            <Collapse in={footerOpening}>
                <div className={style.footer}>
                    <p>Delete this forever?</p>
                    <div className='d-flex flex-row justify-content-around'>
                        <Button variant="secondary" onClick={() => { setFooterOpening(false) }}>Cancel</Button>
                        <Button variant="dark" onClick={() => { handleDelete() }}>Delete</Button>
                    </div>
                </div>
            </Collapse>
        </div>
    </div>
}

const Links = () => {
    const { user } = useAuth()
    const [list, setList] = useState<Ilink[]>([])
    const [headerAdding, setHeaderAdding] = useState<boolean>(false)
    const [linkAdding, setLinkAdding] = useState<boolean>(false)

    const onDragEnd = async (result: any) => {
        if (!result.destination) {
            return;
        }

        const items = await reorder(
            list,
            result.source.index,
            result.destination.index
        );
        setList(items)
    }

    const reorder = async (list: Ilink[], startIndex: any, endIndex: any): Promise<Ilink[]> => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        sortLinks(result.map((v, i) => {
            return {
                id: v.id,
                sort: i
            }
        }))
        return result;
    };

    useEffect(() => {
        if (user?.uid) {
            getLinks(user.uid).then(res => {
                setList(res)
            })
        }
    }, [user?.uid])

    const handleAddHeader = useCallback(() => {
        if (headerAdding) {
            return
        }
        setHeaderAdding(true)

        addLink({
            title: '',
            link: '',
            type: 'header',
            status: true
        }).then((res) => {
            setList([res.data as any, ...list])
        }).finally(() => {
            setHeaderAdding(false)
        })
    }, [headerAdding, list])

    const handleAddLink = useCallback(() => {
        if (linkAdding) {
            return
        }
        setLinkAdding(true)

        addLink({
            link: '',
            title: '',
            type: 'link',
            status: true
        }).then(({ data }) => {
            setList([data as any, ...list])
        }).finally(() => {
            setLinkAdding(false)
        })
    }, [linkAdding, list])

    const onDelete = (id: number) => {
        const index = list.findIndex(v => v.id === id)
        if (index >= 0) {
            list.splice(index, 1)
            setList([...list])
        }
    }

    useEffect(() => {
        setLinks(list)
    }, [list])

    return <Container>
        <div className={style.linksManage}>
            <div className={style.headerOprate}>
                <Button style={{ backgroundColor: '#8E1EBC', border: 'none' }}
                    size={'lg'}
                    onClick={() => {
                        handleAddLink()
                    }}
                >
                    {linkAdding ?
                        <Spinner animation="border" role="status" size='sm'>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> : '+ Link'}
                </Button>
                <Button style={{ backgroundColor: '#000', border: 'none' }}
                    size={'lg'}
                    onClick={() => {
                        handleAddHeader()
                    }}
                >
                    {headerAdding ?
                        <Spinner animation="border" role="status" size='sm'>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> : '+ Header'}
                </Button>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction={'vertical'}>
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={style.list}
                        >
                            {list.map((item, index) => (
                                <Draggable key={item.id} draggableId={`drag_${item.id}`} index={index}>
                                    {(provided) => (
                                        <LinkItem provided={provided} link={item} onDelete={onDelete} />
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    </Container>
}

export default Links