
import axios from "axios";
import { Itoken } from "../types";



export const getTokens = async (uid: string, page: number = 1, page_size: number = 50, without_hide: number = 1) => {
    const response = await axios.get(`/api/tokens/index.json`, {
        params: {
            user_id: uid,
            page,
            page_size,
            without_hide
        }
    })
    return response.data as Array<Itoken>
}

export const getToken = async (id: string) => {
    const response = await axios.get(`/api/tokens/${id}.json`)
    return response.data as Itoken
}

export const sortTokens = async (data: Itoken[]) => {
    return await axios.put(`/api/tokens/sort.json`, {
        tokens: data
    })
}

export const hideTokens = async (data: number[]) => {
    return await axios.post(`/api/tokens/hide.json`, {
        ids: data
    })
}
