import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Container, Row } from 'react-bootstrap'
import Grid from '../../../components/grid/Grid'
import style from './Index.module.scss'
import { SketchPicker } from 'react-color'
import { useCallback, useState } from 'react'
import { Iuser } from '../../../types'
import { setUserInfo } from '../../../redux/user';
import Config from '../../../utils/config'
import { useSelector } from 'react-redux'
import { editUserConfig } from '../../../api/users'

const Appearance = () => {
    const [showColorPicker, setShowColorPicker] = useState<boolean>(false)
    const [showBtnColorPicker, setShowBtnColorPicker] = useState<boolean>(false)
    const [showBtnFontColorPicker, setShowBtnFontColorPicker] = useState<boolean>(false)
    const [showFontColorPicker, setShowFontColorPicker] = useState<boolean>(false)

    const userInfo = useSelector((state: any) => state.user as Iuser)

    const handleSaveField = useCallback((field: string, value: any) => {
        let data: { [key: string]: any } = {}
        data[field] = value
        editUserConfig(data.config)
        setUserInfo({
            ...userInfo,
            ...data
        })
    }, [userInfo])

    const handleBtnType = (buttonRadius: string, buttonType: string,) => {
        if (userInfo) {
            handleSaveField('config', { ...userInfo.config, ...{ buttonRadius, buttonType } })
        }
    }

    const handleBtnColor = (buttonColor: string) => {
        if (userInfo) {
            handleSaveField('config', { ...userInfo.config, ...{ buttonColor } })
        }
    }

    const handleBtnFontColor = (buttonFontColor: string) => {
        if (userInfo) {
            handleSaveField('config', { ...userInfo.config, ...{ buttonFontColor } })
        }
    }

    const handleFontColor = (color: string) => {
        if (userInfo) {
            handleSaveField('config', { ...userInfo.config, ...{ color } })
        }
    }

    const handleFontFamily = (fontFamily: string) => {
        if (userInfo) {
            handleSaveField('config', { ...userInfo.config, ...{ fontFamily } })
        }
    }

    return <Container>
        <div className={style.appearance}>
            <h4>Background</h4>
            <div className={style.container}>
                <div style={{ boxShadow: '0 0 5px #ccc', borderColor: userInfo?.config?.background, background: userInfo?.config?.background }} className={[style.btnstyle, style.rounded].join(' ')} ></div>
                <div className={style.row}>
                    <p className={style.subtitle} style={{ textAlign: 'left' }}>Background Color</p>
                    <div className={style.btnColorPicker}>
                        <span>{userInfo?.config?.background}</span>
                        <div className={style.colorPickerHandle} style={{ background: userInfo?.config?.background }} onClick={() => {
                            setShowColorPicker(!showColorPicker)
                        }}>
                        </div>
                        {showColorPicker &&
                            <div>
                                <div className={style.selectColorMask} onClick={() => {
                                    setShowColorPicker(false)
                                }}></div>
                                <div className={style.selectColor}>
                                    <SketchPicker
                                        onChangeComplete={(color) => {
                                            if (userInfo) {
                                                handleSaveField('config', { ...userInfo.config, ...{ background: color.hex } })
                                            }
                                        }}
                                        color={userInfo?.config?.background}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <h4>Button</h4>
            <div className={style.container}>
                <div className={style.row}>
                    <Grid columns={2}>
                        <div>
                            <div style={{ borderColor: userInfo?.config?.buttonColor }} className={[style.btnstyle, style.outline].join(' ')} onClick={() => { handleBtnType('unset', 'outline') }}>
                                {(userInfo?.config?.buttonType === 'outline' && userInfo.config.buttonRadius === 'unset') && <div className={style.selected}>
                                    <FontAwesomeIcon icon={['fal', 'check-circle']} />
                                </div>}
                            </div>
                            <div style={{ borderColor: userInfo?.config?.buttonColor }} className={[style.btnstyle, style.outline, style.rounded].join(' ')} onClick={() => { handleBtnType('rounded', 'outline') }}>
                                {(userInfo?.config?.buttonType === 'outline' && userInfo.config.buttonRadius === 'rounded') && <div className={style.selected}>
                                    <FontAwesomeIcon icon={['fal', 'check-circle']} />
                                </div>}
                            </div>
                            <div style={{ borderColor: userInfo?.config?.buttonColor }} className={[style.btnstyle, style.outline, style.roundedCircle].join(' ')} onClick={() => { handleBtnType('roundedCircle', 'outline') }}>
                                {(userInfo?.config?.buttonType === 'outline' && userInfo.config.buttonRadius === 'roundedCircle') && <div className={style.selected}>
                                    <FontAwesomeIcon icon={['fal', 'check-circle']} />
                                </div>}
                            </div>
                        </div>
                        <div>
                            <div style={{ borderColor: userInfo?.config?.buttonColor, background: userInfo?.config?.buttonColor }} className={[style.btnstyle].join(' ')} onClick={() => { handleBtnType('unset', 'fill') }}>
                                {(userInfo?.config?.buttonType === 'fill' && userInfo.config.buttonRadius === 'unset') && <div className={style.selected}>
                                    <FontAwesomeIcon icon={['fal', 'check-circle']} />
                                </div>}
                            </div>
                            <div style={{ borderColor: userInfo?.config?.buttonColor, background: userInfo?.config?.buttonColor }} className={[style.btnstyle, style.rounded].join(' ')} onClick={() => { handleBtnType('rounded', 'fill') }}>
                                {(userInfo?.config?.buttonType === 'fill' && userInfo.config.buttonRadius === 'rounded') && <div className={style.selected}>
                                    <FontAwesomeIcon icon={['fal', 'check-circle']} />
                                </div>}
                            </div>
                            <div style={{ borderColor: userInfo?.config?.buttonColor, background: userInfo?.config?.buttonColor }} className={[style.btnstyle, style.roundedCircle].join(' ')} onClick={() => { handleBtnType('roundedCircle', 'fill') }}>
                                {(userInfo?.config?.buttonType === 'fill' && userInfo.config.buttonRadius === 'roundedCircle') && <div className={style.selected}>
                                    <FontAwesomeIcon icon={['fal', 'check-circle']} />
                                </div>}
                            </div>
                        </div>
                    </Grid>
                </div>
                <div className={style.row}>
                    <p className={style.subtitle} style={{ textAlign: 'left' }}>Button Color</p>
                    <div className={style.btnColorPicker}>
                        <span>{userInfo?.config?.buttonColor}</span>
                        <div className={style.colorPickerHandle} style={{ background: userInfo?.config?.buttonColor }} onClick={() => {
                            setShowBtnColorPicker(!showBtnColorPicker)
                        }}>
                        </div>
                        {showBtnColorPicker &&
                            <div>
                                <div className={style.selectColorMask} onClick={() => {
                                    setShowBtnColorPicker(false)
                                }}></div>
                                <div className={style.selectColor}>
                                    <SketchPicker
                                        onChangeComplete={(color) => {
                                            if (userInfo) {
                                                handleBtnColor(color.hex)
                                            }
                                        }}
                                        color={userInfo?.config?.buttonColor}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={style.row}>
                    <p className={style.subtitle} style={{ textAlign: 'left' }}>Button Font Color</p>
                    <div className={style.btnColorPicker}>
                        <span>{userInfo?.config?.buttonFontColor}</span>
                        <div className={style.colorPickerHandle} style={{ background: userInfo?.config?.buttonFontColor }} onClick={() => {
                            setShowBtnFontColorPicker(!showBtnFontColorPicker)
                        }}>
                        </div>
                        {showBtnFontColorPicker &&
                            <div>
                                <div className={style.selectColorMask} onClick={() => {
                                    setShowBtnFontColorPicker(false)
                                }}></div>
                                <div className={style.selectColor}>
                                    <SketchPicker
                                        onChangeComplete={(color) => {
                                            if (userInfo) {
                                                handleBtnFontColor(color.hex)
                                            }
                                        }}
                                        color={userInfo?.config?.buttonFontColor}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <h4>Font</h4>
            <div className={style.container}>
                <Row>
                    {Config.Fonts.map(v => {
                        return <Col xs={6} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ marginBottom: '1.5rem' }} key={v}>
                            <div className={style.square} onClick={() => {
                                handleFontFamily(v)
                            }}>
                                <div className={style.body} style={{ borderColor: userInfo?.config ? (userInfo.config.fontFamily === v ? '#aa57cd' : '#dcdcdc') : '#dcdcdc' }}>
                                    <p style={{ fontFamily: v }}>Aa</p>
                                    <p className={style.name}>{v}</p>
                                </div>
                            </div>
                        </Col>
                    })}
                </Row>
                <div className={style.row}>
                    <p className={style.subtitle} style={{ textAlign: 'left', marginTop: '10px' }}>Font Color</p>
                    <div className={style.btnColorPicker}>
                        <span>{userInfo?.config?.color} {showBtnColorPicker}</span>
                        <div className={style.colorPickerHandle} style={{ background: userInfo?.config?.color }} onClick={() => {
                            setShowFontColorPicker(!showFontColorPicker)
                        }}>
                        </div>
                        {showFontColorPicker &&
                            <div>
                                <div className={style.selectColorMask} onClick={() => {
                                    setShowFontColorPicker(false)
                                }}></div>
                                <div className={style.selectColor}>
                                    <SketchPicker
                                        onChangeComplete={(color) => {
                                            if (userInfo) {
                                                handleFontColor(color.hex)
                                            }
                                        }}
                                        color={userInfo?.config?.color}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div style={{ height: '100px' }}></div>
        </div>
    </Container>
}

export default Appearance