
import axios from "axios";
import { Iuser } from "../types";

export const getUserByName = async (name: string) => {
    const response = await axios.get(`/api/user/${name}.json`, {
        params: {
            type: 'name'
        }
    })
    return response.data as Iuser
}

export const getUserById = async (uid: string) => {
    const response = await axios.get(`/api/user/${uid}.json`, {
        params: {
            type: 'id'
        }
    })
    return response.data as Iuser
}

export const editUser = async (data: { [key: string]: any }) => {
    return axios.put(`/api/user/${data.id}.json`, data)
}

export const editSocialLinks = async (data: { [key: string]: any }) => {
    return axios.put(`/api/user/sociallinks.json`, {
        data
    })
}

export const editUserConfig = async (data: { [key: string]: any }) => {
    return axios.put(`/api/user/config.json`, {
        data
    })
}

