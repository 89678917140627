import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LinkContainer } from 'react-router-bootstrap'
import { useHistory } from 'react-router-dom'
import style from './GridPhoto.module.scss'
const GridPhoto = ({ data, color, borderColor, viewCollection }: any) => {
    const history = useHistory()
    return <div className={style.photo} style={{ borderColor: borderColor, color: color }}>
        <div className={style.square}>
            <div className={style.container} style={{ backgroundImage: `url(${data.image_url})` }}>

            </div>
        </div>
        <div className={style.info}>
            {data.community !== undefined && <div className={style.collectioinInfo} style={{ opacity: 0.7 }}>
                <p>{data.community.name}</p>
                {data.community.safelist_request_status === 'verified' &&
                    <FontAwesomeIcon icon={['fas', 'badge-check']} color={color} style={{ fontSize: '12px' }} />}
            </div>}
            {data.name !== undefined && <p>{data.name}</p>}
            {viewCollection === true &&
                <a href={`/collection/${data.community.slug}`} rel="noreferrer" onClick={(e)=>{
                    e.preventDefault()
                    e.stopPropagation()
                    history.push(`/collection/${data.community.slug}`)
                    return false
                }}>
                    <div className={style.viewCollection}>
                        <FontAwesomeIcon icon={['fas', 'user-friends']} style={{fontSize:'15px'}} />
                    </div>
                </a>
            }
        </div>
    </div>
}

export default GridPhoto