import React from 'react';
import { Col } from 'react-bootstrap';
import GridPhoto from './GridPhoto';
import style from './Item.module.scss'

const defaultProps = {
    borderColor: 'rgb(229, 232, 235)',
    fontColor: 'inherit',
    viewCollection: false,
};
type IProps = {
    data: any,
    onClick?: Function,
    borderColor: string,
    fontColor: string,
    viewCollection: boolean,
    badge?: React.ReactElement
} & typeof defaultProps;

const Item = (props: IProps) => {
    const { data, onClick, fontColor, borderColor, viewCollection, badge } = props

    return (
        <Col xs={6} sm={3} md={3} lg={3} xl={3} xxl={3} onClick={() => {
            onClick && onClick(props.data)
        }}>
            <div className={style.container}>
                {badge}
                <GridPhoto data={data} color={fontColor} borderColor={borderColor} viewCollection={viewCollection} />
            </div>
        </Col>
    );
}


Item.defaultProps = defaultProps;
export default Item