import { Redirect } from "react-router";
import { NiftyAuthenticationState, useAuth } from "../../contexts/AuthContext";
import Loading from "../../components/loading/index";
import { useCallback, useEffect, useState } from "react";
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import { httpsCallable } from "firebase/functions";
import useInterval from "@use-it/interval";
import Upload, { IuploadFile } from "../../components/upload";
import { toast } from "react-toastify";
import { Itoken } from "../../types";
import GridBootstrap from "../../components/grid/Index";
import GridItem from "../../components/grid/Item";
import { Debounced } from '../../utils/helpers'
import Config from "../../utils/config";
import { getUserByName, editUser } from "../../api/users";

export default function Onboard() {
  let { user, authState, firebaseFunctions } = useAuth();
  let [onboardData, setOnboardData] = useState(null);
  const [onboardFunction, setOnboardFunction] = useState(() => () => { });
  const [onboardFunctionDelay, setOnboardFunctionDelay] = useState<number | null>(0);

  const [username, setUsername] = useState<string>('')
  const [usernameValidation, setUsernameValidation] = useState<string>('')
  const [avatar, setAvatar] = useState<string>('')
  const [avatarType, setavatarType] = useState<string>('nft')

  useEffect(() => {
    setTimeout(() => {
      var onboardFunctionCount = 0;
      if (authState === NiftyAuthenticationState.AUTHENTICATED) {
        setOnboardFunction(() => () => {
          if (onboardFunctionCount === 100) {
            setOnboardFunctionDelay(null); // Prevent unnecessarily high number of queries
          } else {
            setOnboardFunctionDelay(5000);
          }
          onboardFunctionCount++;
          const doOnboarding = httpsCallable(firebaseFunctions, "doOnboarding");
          doOnboarding().then((result: any) => {
            setOnboardData(result.data);
            if (result.data.isSyncing < 0) {
              setOnboardFunctionDelay(null);
            }
          });
        });
      }
    }, 1000);
  }, [authState]);

  useInterval(() => {
    onboardFunction();
  }, onboardFunctionDelay);

  const handleavatarType = (value: string) => {
    setavatarType(value)
  }

  const handleComplete = useCallback(() => {
    const save = async () => {
      let data = {
        username,
        avatar,
      }

      if (username.length === 0) {
        if (usernameValidation.length === 0) {
          setUsernameValidation('user name  is required.')
        }
        return
      }
      if ((avatarType === 'nft' || avatarType === 'upload') && avatar.length === 0) {
        toast.error('Choose your avatar')
        return
      }
      await editUser(data)
      window.location.href = '/admin'
    }
    save()
  }, [avatar, avatarType, user?.uid, username])

  const handelUpload = (e: IuploadFile) => {
    if (e && e.status === 2 && e.metadata) {
      setAvatar(`https://niftynet.photos/${e.metadata.fullPath}`)
    }
  }

  const debounce = new Debounced().use(checkUsername)

  async function checkUsername(this: any, name: string) {
    if (name.length === 0) {
      setUsernameValidation('user name  is required.')
      setUsername('')
      return
    }

    if (/\s/.test(name)) {
      setUsernameValidation('The username cannot contain Spaces.')
      setUsername('')
      return
    }

    let isusable = true
    for (let i = 0; i < Config.DisabledUsername.length; i++) {
      const disableName = Config.DisabledUsername[i];
      if (name.toLocaleLowerCase().includes(disableName.toLocaleLowerCase())) {
        isusable = false
        break
      }
    }

    if (!isusable) {
      setUsernameValidation('The user name is not available.')
      setUsername('')
      return
    }

    const userExisting = await getUserByName(name)
    if (userExisting) {
      setUsernameValidation('The user name already exists.')
      setUsername('')
    } else {
      setUsernameValidation('')
      setUsername(name)
    }
  }

  switch (authState) {
    case NiftyAuthenticationState.LOADING:
      return <Loading />;
    case NiftyAuthenticationState.AUTHENTICATED:
      const component = (
        <Container>
          <div>
            <h2 className="mb-4 text-primary">Complete Sign Up</h2>
            <h4>Choose your username</h4>
            <InputGroup className="mb-4" hasValidation>
              <InputGroup.Text id="inputGroupPrepend">
                niftynet.com/
              </InputGroup.Text>
              <Form.Control
                size="lg"
                type="text"
                placeholder="username"
                aria-describedby="inputGroupPrepend"
                defaultValue={username}
                required
                isInvalid={usernameValidation.length > 0 ? true : false}
                onInput={(e: any) => {
                  debounce(e.target.value)
                }}

              />
              <Form.Control.Feedback type={'invalid'}>
                {usernameValidation}
              </Form.Control.Feedback>
            </InputGroup>
            <h4>Choose your profile picture</h4>

            <Form>
              <Form.Check
                name="pfpType"
                type="radio"
                id="pfpTypeNft"
                label="From your NFT collection"
                value={'nft'}
                defaultChecked={avatarType === 'nft'}
                onChange={(e) => { handleavatarType(e.target.value) }}
              />
              <Form.Check
                name="pfpType"
                type="radio"
                id="pfpTypeUpload"
                label="Upload your own image"
                value={'upload'}
                defaultChecked={avatarType === 'upload'}
                onChange={(e) => { handleavatarType(e.target.value); setAvatar('') }}
              />
              <Form.Check
                name="pfpType"
                type="radio"
                id="pfpTypeNone"
                label="No profile picture"
                value={'none'}
                defaultChecked={avatarType === 'none'}
                onChange={(e) => { handleavatarType(e.target.value); setAvatar('') }}
              />
            </Form>
            {avatarType === 'nft' &&
              <div className="mb-4">
                <OnboardData data={onboardData} onSelected={(image: any) => { setAvatar(image) }} />
              </div>
            }
            {avatarType === 'upload' &&
              <div className="mb-4">
                <Upload onChange={handelUpload}>
                  <Button>upload image</Button>
                </Upload>
              </div>
            }
            <div style={{ zIndex: 10001, position: 'fixed', bottom: '0', left: '0', right: '0', height: '5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button size="lg" variant="primary" onClick={() => {
                setTimeout(() => {
                  handleComplete()
                }, 800);
              }}>
                Complete Sign Up
              </Button>
            </div>
          </div>
        </Container>
      );
      return component;
    case NiftyAuthenticationState.UNAUTHENTICATED:
      return <Redirect to="/admin/login" />;
  }
}


const OnboardData = ({
  data,
  onSelected,
}: {
  data: {
    isSyncing: number;
    nfts: Itoken[];
  } | null;
  onSelected: Function
}) => {
  const [selected, setSelected] = useState<string>()
  if (data == null) {
    return (
      <div>
        <Loading />
        {`Loading your collection...`}
      </div>
    );
  }

  return (
    <div>
      {data.nfts ?
        <div>
          <GridBootstrap>
            {data.nfts.map((v, i) => (
              <GridItem data={v} key={v.id} onClick={() => {
                onSelected && onSelected(`${v.image_url}?source=nft&id=${v.id}`)
                setSelected(v.id)
              }} />
            ))}
          </GridBootstrap>
          <Loading show={data.isSyncing >= 0} />
        </div>
        : <div>We couldn't find any NFTs in your collection.</div>}
    </div>
  );

};
