import style from './Index.module.scss'
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormControl, Image, InputGroup, Modal, Row, Spinner } from 'react-bootstrap';

import { Itoken, Iuser } from '../../../types';
import Upload, { IuploadFile } from '../../../components/upload';
import { setUserInfo } from '../../../redux/user';
import Grid from '../../../components/grid/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nfts from './Nfts';

import Config from '../../../utils/config'
import { useSelector } from 'react-redux';
import { editSocialLinks, editUser, getUserByName } from '../../../api/users';
import { hideTokens, sortTokens } from '../../../api/tokens';
import Avatar from '../../../components/avatar';
import Images from '../../../images';
import Loading from '../../../components/loading'
import { toast } from 'react-toastify';
import { Debounced } from '../../../utils/helpers';
import Hexagon from '../../../components/avatar/Hexagon';
import TokensHide from './TokensHide';


const Profile = () => {
    const [tokenModal, settokenModal] = useState<string>('')
    const [avatarModal, setAvatarModal] = useState<boolean>(false)
    const [newavatar, setNewavatar] = useState<string>()
    const [tmpToken, settmpToken] = useState<Itoken>()
    const [keywords, setKeywords] = useState<string>()
    const [avatarUploading, setAvatarUploading] = useState<boolean>(false)
    const [usernameValidation, setUsernameValidation] = useState<string>('')
    const [usernameSaving, setUsernameSaveing] = useState<boolean>(false)
    const [hideTokensModal, setHideTokensModal] = useState<boolean>(false)

    const userInfo = useSelector((state: any) => state.user as Iuser)

    const [hideTokenIds, setHideTokenIds] = useState<number[]>(userInfo.tokens_hide)

    useEffect(() => {
        setHideTokenIds(userInfo.tokens_hide)
    }, [userInfo.tokens_hide])

    const handleSaveField = useCallback((field: string, value: any) => {
        if (userInfo[field] === value) {
            return
        }
        const newUser = {
            ...userInfo,
            [field]: value
        }
        editUser(newUser)
        setUserInfo(newUser)
    }, [userInfo])

    const handelUpload = useCallback((e: IuploadFile) => {
        if (e) {
            if (e.status === 0) {
                setAvatarUploading(true)
            } else if (e.status === 1) {
                setAvatarUploading(true)
            } else if (e.status === 2) {
                handleSaveField('avatar', `https://niftynet.photos/${e.metadata.fullPath}`)
                setAvatarUploading(false)
                handleAvatarModalClose()
            } else {
                setAvatarUploading(false)
                toast.error(e.error)
            }
        }
    }, [avatarUploading, handleSaveField])

    const handleSave = () => {
        if (tokenModal === 'avatar') {
            if (newavatar) {
                handleSaveField('avatar', newavatar)
                setNewavatar(undefined)
            }
        } else if (userInfo && tmpToken) {
            const index = parseInt(tokenModal.split('_')[1])
            userInfo.top_tokens[index] = { ...tmpToken, sort: index + 1 }
            settmpToken(undefined)
            handleTopTokensSave(userInfo.top_tokens)
        }
        handleClose()
    }

    const handleClose = () => { setKeywords(undefined); settokenModal('') };
    const handleShow = (type: string) => settokenModal(type);

    const handleAvatarModalClose = () => { setAvatarModal(false) };
    const handleAvatarModalShow = () => setAvatarModal(true);

    const handleSocialLinkSave = (social_links: any) => {
        editSocialLinks(social_links)
        setUserInfo({
            ...userInfo,
            social_links
        })
    }

    const handleTopTokensSave = (top_tokens: Itoken[]) => {
        sortTokens(top_tokens)
        setUserInfo({
            ...userInfo,
            top_tokens: top_tokens.map(v => {
                if (v === null) {
                    return v
                } else {
                    return v.sort === 0 ? null : v
                }
            })
        })
    }

    const handleSaveUserName = useCallback((username: string) => {
        if (userInfo.username === username) {
            return
        }
        setUsernameSaveing(true)
        editUser({
            username,
            id: userInfo.id
        }).then((res) => {
            if (res.data.status) {
                setUserInfo({
                    ...userInfo,
                    username: username
                })
            } else {
                setUsernameValidation(res.data.message)
            }
        }).finally(() => {
            setUsernameSaveing(false)
        })
    }, [userInfo])

    const debounce = new Debounced().use(checkUsername)

    async function checkUsername(this: any, name: string) {
        if (name.length === 0) {
            setUsernameValidation('username is required.')
            return false
        }

        if (userInfo.username !== name && name.length > 16) {
            setUsernameValidation('The name contains a maximum of 16 characters');
            return false
        }

        if (/\s/.test(name)) {
            setUsernameValidation('The username cannot contain Spaces.')
            return false
        }

        let isusable = true
        for (let i = 0; i < Config.DisabledUsername.length; i++) {
            const disableName = Config.DisabledUsername[i];
            if (name.toLocaleLowerCase().includes(disableName.toLocaleLowerCase())) {
                isusable = false
                break
            }
        }

        if (!isusable) {
            setUsernameValidation('The username is not available.')
            return
        }

        const userExisting = await getUserByName(name)
        if (userExisting && userExisting.id !== userInfo.id) {
            setUsernameValidation('The user name already exists.')
            return false
        } else {
            setUsernameValidation('')
            return true
        }
    }


    let avatar = Images.default_avatar
    if (userInfo && userInfo.avatar) {
        avatar = userInfo.avatar
    }

    return <Container>
        <Modal show={tokenModal.length > 0} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                {/* <Modal.Title>Set Avatar</Modal.Title> */}
                <input placeholder='Search' className={style.search} type="text" onChange={(e) => {
                    setKeywords(e.target.value)
                }} />
            </Modal.Header>
            <Modal.Body>
                <div style={{ maxHeight: '70vh', overflowY: 'auto' }} id='selectNftBox'>
                    <Nfts onSelected={(nft: Itoken) => {
                        if (tokenModal === 'avatar') {
                            setNewavatar(`${nft.image_url}?source=nft&id=${nft.id}`)
                        } else if (userInfo) {
                            settmpToken(nft)
                        }
                    }} trigger='selectNftBox' filter={keywords} exclude={
                        (() => {
                            if (tokenModal === 'avatar') {
                                return []
                            } else if (userInfo.top_tokens) {
                                return userInfo.top_tokens.filter(v => {
                                    return v !== null
                                }).map(v => {
                                    return parseInt(v.id)
                                })
                            } else {
                                return []
                            }
                        })()
                    } />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={avatarModal} onHide={handleAvatarModalClose} size="lg">
            <Modal.Header closeButton>
                Set Profile Image
            </Modal.Header>
            <Modal.Body>
                <div className={style.chooseAvatarType} onClick={() => {
                    handleShow('avatar')
                    handleAvatarModalClose()
                }}>
                    <Avatar src={Images.default_avatar} size='4rem' roundedCircle></Avatar>
                    <div className={style.center}>
                        <h5>NFTS</h5>
                        <p>Choose from your NFTS</p>
                    </div>
                    <FontAwesomeIcon icon={["fas", "angle-right"]} size={'1x'} />
                </div>
                <Upload onChange={handelUpload} path='profile'>
                    <div className={style.chooseAvatarType}>
                        <FontAwesomeIcon icon={["fas", "cloud-upload"]} size={'3x'} color="#37E09B" />
                        <div className={style.center}>
                            <h5>Upload</h5>
                            <p>Upload your own image</p>
                        </div>
                        <FontAwesomeIcon icon={["fas", "angle-right"]} size={'1x'} />
                    </div>
                </Upload>
            </Modal.Body>
        </Modal>
        <div className={style.profile}>
            <h4>Profile</h4>
            <div className={style.container}>
                <p className={style.subtitle}>Profile Picture</p>
                <div className={style.avatarConfig}>
                    {avatar.includes('source=nft') ?
                        <Hexagon src={avatar} size='5rem' borderColor='#333' />
                        :
                        <Avatar src={avatar} size='5rem' roundedCircle />
                    }
                    <div className={style.avatarMenus}>
                        <Row>
                            <Col xs={12} md={6}>
                                <Button onClick={handleAvatarModalShow} className={style.select}>Pick an image</Button>
                            </Col>
                            <Col xs={12} md={6}>
                                <Button onClick={() => { handleSaveField('avatar', null) }} variant="light">Remove</Button>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                        Username
                        {usernameSaving && <Spinner animation="border" size='sm' />}
                    </Form.Label>
                    <Form.Control
                        defaultValue={userInfo.username}
                        name="username" type="text"
                        placeholder="Choose your username"
                        isInvalid={usernameValidation.length > 0 ? true : false}
                        onBlur={(e) => {
                            handleSaveUserName(e.target.value)
                        }}
                        onChange={(e) => {
                            debounce(e.target.value)
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{usernameValidation}</Form.Control.Feedback>
                    <Form.Text className="text-muted">
                        Changing your username will change your NiftyNet URL.
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Display Name</Form.Label>
                    <Form.Control
                        defaultValue={userInfo.display_name}
                        name="display_name" type="text"
                        // placeholder={userInfo?.display_name ? userInfo?.display_name : userInfo?.username ? '@' + userInfo?.username : ''}
                        onBlur={(e) => {
                            handleSaveField('display_name', e.target.value)
                        }}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Bio</Form.Label>
                    <Form.Control
                        defaultValue={userInfo.bio}
                        name="bio" as="textarea"
                        rows={3}
                        onBlur={(e) => {
                            handleSaveField('bio', e.target.value)
                        }}
                    />
                </Form.Group>
            </div>
            <h4>Social Links</h4>
            <div className={style.container}>
                {userInfo.social_links && Object.keys(userInfo.social_links).map(k => {
                    let link = Config.social_links[k]
                    if (link === undefined) {
                        return null
                    }
                    link['value'] = userInfo.social_links[k] || ''
                    return <Form.Group className="mb-3" controlId="formBasicEmail" key={k}>
                        <Form.Label>{link.name}</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                                {link.host}
                            </InputGroup.Text>
                            <FormControl
                                placeholder={link.placeholder}
                                aria-label={link.name}
                                aria-describedby="basic-addon1"
                                defaultValue={link.value}
                                onBlur={(e) => {
                                    console.log(e.target.value, userInfo.social_links[k]);

                                    if (e.target.value != userInfo.social_links[k]) {
                                        userInfo.social_links[k] = e.target.value
                                        handleSocialLinkSave(userInfo.social_links)
                                    }
                                }}
                            />
                        </InputGroup>
                    </Form.Group>
                })}
            </div>
            {userInfo &&
                <div>
                    <h4>Featured NFTs</h4>
                    <div className={style.container}>
                        <div className={style.row}>
                            <p className={style.topnftTitle}>The NFTs you choose here will show at the top of your collection.</p>
                            <Grid columns={4}>
                                {userInfo.top_tokens && userInfo.top_tokens.map((token, i) => {
                                    return <div className={style.square} key={i + 1}>
                                        <div className={style.body} onClick={() => {
                                            settokenModal(`top_${i}`)
                                        }}>
                                            {token ? <Image src={token.image_url} rounded /> : <FontAwesomeIcon icon={["fal", "plus"]} size={'2x'} />}
                                        </div>
                                        {token && <div onClick={() => {
                                            userInfo.top_tokens[i].sort = 0
                                            handleTopTokensSave(userInfo.top_tokens)
                                        }} className={style.remove}>
                                            <FontAwesomeIcon icon={["fas", "times"]} size={'1x'} />
                                        </div>}
                                    </div>
                                })}
                            </Grid>
                        </div>
                        <div className={style.row}>
                            <Button onClick={() => {
                                setHideTokensModal(true)
                            }} style={{
                                backgroundColor: '#8e20bc',
                                borderColor: '#8e20bc'
                            }}>{hideTokenIds ? hideTokenIds.length : '0'} hidden NFTs</Button>
                        </div>
                        <Modal show={hideTokensModal} onHide={() => {
                            setHideTokensModal(false)
                        }} size="lg">
                            <Modal.Header closeButton>
                                {/* <input placeholder='Search' className={style.search} type="text" onChange={(e) => {
                                    setKeywords(e.target.value)
                                }} /> */}
                            </Modal.Header>
                            <Modal.Body>
                                <div style={{ maxHeight: '70vh', overflowY: 'auto' }} id='selectHideTokensBox'>
                                    <TokensHide hides={hideTokenIds} trigger={'selectHideTokensBox'} onChange={(e: any) => {
                                        const existing = hideTokenIds === undefined ? -1 : hideTokenIds.findIndex(v => {
                                            return v === e
                                        })
                                        if (existing < 0) {
                                            setHideTokenIds(pre => {
                                                if (pre) {
                                                    return [...pre, e]
                                                } else {
                                                    return [e]
                                                }
                                            })
                                        } else {
                                            hideTokenIds.splice(existing, 1)
                                            setHideTokenIds([...hideTokenIds])
                                        }
                                    }} />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => {
                                    setHideTokenIds(userInfo.tokens_hide)
                                    setHideTokensModal(false)
                                }}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={() => {
                                    hideTokens(hideTokenIds).then(() => {
                                        setUserInfo({
                                            ...userInfo,
                                            tokens_hide: hideTokenIds
                                        })
                                    })
                                    setHideTokensModal(false)
                                }}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            }
        </div>
        <Loading show={avatarUploading} fullScreen zIndex={99999} />
    </Container >
}

export default Profile