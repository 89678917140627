import style from './Index.module.scss'
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import Onboard from "./Onboard";
import Links from "../admin/links/Links";
import Profile from "../admin/profile/Index";
import Appearance from "../admin/appearance/Index";
// import Login from './Login'
import Login from './login/Index'
import Layout from './layout/Index';
import Sync from './sync/Index';
import Account from './account/Index';


export default function Index() {
  let match = useRouteMatch();
  return <Switch>
    <Route path={`${match.path}/login`}>
      <Login />
    </Route>
    <Route path={`${match.path}/onboard`}>
      <Layout showHeader showRight={false} showTabs={false} showPreview={false}><Onboard /></Layout>
    </Route>
    <Route path={`${match.path}/links`}>
      <Layout showHeader showRight showTabs showPreview><Links /></Layout>
    </Route>
    <Route path={`${match.path}/profile`}>
      <Layout showHeader showRight showTabs showPreview><Profile /></Layout>
    </Route>
    <Route path={`${match.path}/appearance`}>
      <Layout showHeader showRight showTabs showPreview><Appearance /></Layout>
    </Route>
    <Route path={`${match.path}/sync`}>
      <Layout showHeader showRight showTabs showPreview><Sync /></Layout>
    </Route>
    {/* <Route path={`${match.path}/account`}>
      <Layout showHeader showRight showTabs showPreview><Account /></Layout>
    </Route> */}
    <Route path={`${match.path}/`}>
      <Redirect to={`${match.path}/profile`} />
    </Route>
  </Switch>
}