import React, { FC, useState } from "react";
import isDev, { HOSTNAME } from "../utils/helpers";
// import { fcmDb, FcmMessage } from "../fcm";
// import {
//   getMessaging,
//   getToken,
//   onMessage,
//   Messaging,
// } from "firebase/messaging";
import { initializeApp, FirebaseApp } from "firebase/app";
import { Functions } from "firebase/functions";
import {
  signInWithCustomToken,
  User,
  Auth,
  getAuth,
  connectAuthEmulator,
} from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getAnalytics, Analytics } from "firebase/analytics";
import { connectFunctionsEmulator, getFunctions } from "@firebase/functions";
import { UserData } from "niftynet-helpers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
// import { useLiveQuery } from "dexie-react-hooks";
// import Dexie from "dexie";
import { collection, doc, getDoc } from "firebase/firestore";
import { setUserInfo } from '../redux/user'
import Images from "../images";
import { firebaseLogin } from "../api/login";
import Config from "../utils/config";
import { getUserById } from "../api/users";

// Initialize Firebase app
const firebaseConfig = {
  apiKey: "AIzaSyAbjGkPYxj9r8Z0cEeEnszP0DILNyLqyV8",
  authDomain: "niftynet-dev.firebaseapp.com",
  projectId: "niftynet-dev",
  storageBucket: "niftynet-dev.appspot.com",
  messagingSenderId: "542387236953",
  appId: "1:542387236953:web:3d8b4cc7792f4f61f94148",
  measurementId: "G-VFCJ0E4B4J"
};

export const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase auth
export const firebaseAuth = getAuth(firebaseApp);
// if (isDev()) {
//   connectAuthEmulator(firebaseAuth, "http://localhost:9099");
// }

// Initialize Firebase functions
const firebaseFunctions = getFunctions(firebaseApp, "us-central1");
// if (isDev()) {
//   connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
// }

// Initialize Firebase functions
const firestore = getFirestore(firebaseApp);
// if (isDev()) {
//   connectFirestoreEmulator(firestore, 'localhost', 8088)
// }

// Initialize Firebase messaging
// const firebaseMessaging = getMessaging(firebaseApp);
// onMessage(firebaseMessaging, (payload) => {
//   console.log("Received foreground message ", payload);
//   fcmDb.messages.add({
//     payload: payload,
//   });
// });

// Initialize Firebase analytics
const firebaseAnalytics = getAnalytics(firebaseApp);

export enum OnboardState {
  ONBOARDING = "onboarding",
  ONBOARDED = "onboarded",
}

export enum NiftyRenderState {
  LOADING = "loading",
  LOADED = "loaded",
}

export enum NiftyAuthenticationState {
  AUTHENTICATED = "authenticated",
  UNAUTHENTICATED = "unauthenticated",
  LOADING = "loading",
}

const AuthContext = React.createContext<{
  user: User | null;
  authState: NiftyAuthenticationState;
  renderState: NiftyRenderState;
  onboardState: OnboardState;
  login: Function;
  logout: Function;
  focusState: boolean;
  firebaseApp: FirebaseApp;
  firebaseAuth: Auth;
  firebaseFunctions: Functions;
  // firebaseMessaging: Messaging;
  firebaseAnalytics: Analytics;
  // fcmToken: string | null;
  // fcmMessages: FcmMessage[];
}>({
  user: null,
  authState: NiftyAuthenticationState.LOADING,
  renderState: NiftyRenderState.LOADING,
  onboardState: OnboardState.ONBOARDED,
  login: () => { },
  logout: () => { },
  firebaseApp: firebaseApp,
  firebaseAuth: firebaseAuth,
  firebaseFunctions: firebaseFunctions,
  // firebaseMessaging: firebaseMessaging,
  firebaseAnalytics: firebaseAnalytics,
  // fcmToken: null,
  focusState: true,
  // fcmMessages: [],
});

declare global {
  interface Window {
    ethereum: any;
  }
}

export const AuthProvider: FC<any> = ({ children, history }) => {

  let [authState, setAuthState] = useState<NiftyAuthenticationState>(
    NiftyAuthenticationState.LOADING
  );

  let [renderState, setRenderState] = useState<NiftyRenderState>(
    NiftyRenderState.LOADING
  );

  let [onboardState, setOnboardState] = useState<OnboardState>(
    OnboardState.ONBOARDED
  );

  // let [fcmToken, setFcmToken] = useState<string | null>(null);

  let [focusState, setFocusState] = useState<boolean>(
    window.document.hasFocus()
  );

  const onFocus = () => {
    // setFocusState(true);
  };

  const onBlur = () => {
    // setFocusState(false);
  };

  // const fcmMessagesQuery = () => fcmDb.messages.toArray();
  // const fcmHookMessages = useLiveQuery(fcmMessagesQuery);
  // let [fcmMessages, setFcmMessages] = useState<FcmMessage[]>([]);
  // React.useEffect(() => {
  //   Dexie.waitFor(fcmMessagesQuery()).then((messages) => {
  //     if (!messages) {
  //       setFcmMessages([]);
  //     } else {
  //       setFcmMessages(messages);
  //     }
  //   });
  // }, [fcmHookMessages, focusState]);

  let [user, setUser] = useState<any>(firebaseAuth.currentUser);
  React.useEffect(() => {
    // Get FCM token
    // async function getFcmToken(): Promise<string | null> {
    //   const registration = await navigator.serviceWorker
    //     .register("../firebase-messaging-sw.js")
    //     .catch(function (err) {
    //       console.log("Service worker registration failed, error:", err);
    //       return null;
    //     });
    //   if (registration == null) {
    //     return null;
    //   }

    //   const currentToken = await getToken(firebaseMessaging, {
    //     vapidKey:
    //       "BDJh9As-yLGJhA12UDQ9n1lBNCsvj-YCAmNoH-1c2aPt94zgG8FO2FN9CVO8o-_b1KyedTSSFGFRI6mlka6hNgk",
    //     serviceWorkerRegistration: registration,
    //   }).catch((err) => {
    //     console.log("An error occurred while retrieving token. ", err);
    //     return null;
    //   });

    //   if (currentToken == null) {
    //     return null;
    //   }

    //   // TODO: Send the token to your server and update the UI if necessary

    //   console.log("current token: " + currentToken);
    //   return currentToken;
    // }

    // if ("serviceWorker" in navigator) {
    //   getFcmToken().then((token) => {
    //     setFcmToken(token);
    //   });
    // }

    // // Register focus events
    // window.addEventListener("focus", onFocus);
    // window.addEventListener("blur", onBlur);

    // Subscribe to firebase state changes
    const unsubscribe = firebaseAuth.onAuthStateChanged((fbUser) => {
      if (fbUser != null) {
        setAuthState(NiftyAuthenticationState.AUTHENTICATED);
      } else {
        setAuthState(NiftyAuthenticationState.UNAUTHENTICATED);
      }
      setUser(fbUser);
    });
    return () => {
      unsubscribe();
      // window.removeEventListener("focus", onFocus);
      // window.removeEventListener("blur", onBlur);
    };
  }, []);

  React.useEffect(() => {
    switch (authState) {
      case NiftyAuthenticationState.AUTHENTICATED:
        if (firebaseAuth.currentUser?.uid) {
          getUserById(firebaseAuth.currentUser.uid).then(res => {
            if (!res) {
              logout();
            } else {
              setUserInfo(res).then(() => {
                history && history.push("/admin");
              })
            }
          }).catch(() => {
            logout();
          })
        }
        break;
      case NiftyAuthenticationState.UNAUTHENTICATED:
        setRenderState(NiftyRenderState.LOADED);
        break;
      case NiftyAuthenticationState.LOADING:
        setRenderState(NiftyRenderState.LOADING);
        break;
    }
  }, [authState]);

  // get user info form firestore
  // useEffect(() => {
  //   const getData = async () => {
  //     const userDoc = await getDoc(doc(collection(firestore, `users`), user.uid))
  //     const userDocData = userDoc.data() as any
  //     if (userDocData.avatar === undefined || userDocData.avatar.length === 0) {
  //       userDocData['avatar'] = Images.default_avatar
  //     }
  //     userDocData['id'] = userDoc.id
  //     setUserInfo(userDocData)
  //   }
  //   if (user) {
  //     getData()
  //   }
  // }, [user])

  async function login(type = "MetaMask") {
    setAuthState(NiftyAuthenticationState.LOADING);
    let loginState = false
    try {
      switch (type) {
        case "WalletConnect":
          const provider = new WalletConnectProvider({
            infuraId: "202a043a529240abaef6f554b3485071", // Required
          });
          await provider.enable();
          const web3 = new Web3(provider as any);
          const accounts = await web3.eth.getAccounts();
          loginState = await doLogin(accounts, web3);
          provider.close()
          if (loginState) {
            console.log("logged in user: " + firebaseAuth!.currentUser!.uid);
          } else {
            setAuthState(NiftyAuthenticationState.UNAUTHENTICATED);
          }
          break;
        default:
          if (typeof window.ethereum !== "undefined") {
            console.log("MetaMask is installed!");
            const accounts = await window.ethereum.request({
              method: "eth_requestAccounts",
            });
            
            const web3 = new Web3(Web3.givenProvider);
            loginState = await doLogin(accounts, web3);
            if (loginState) {
              console.log("logged in user: " + firebaseAuth!.currentUser!.uid);
            } else {
              setAuthState(NiftyAuthenticationState.UNAUTHENTICATED);
            }
          } else {
            window.location.href = `https://metamask.app.link/dapp/${HOSTNAME}/admin`;
          }
          break;
      }
    } catch (error: any) {
      console.error(`Error (${error.code}): ${error.message}`);
      setAuthState(NiftyAuthenticationState.UNAUTHENTICATED);
    }
    return loginState
  }

  function logout() {
    firebaseAuth.signOut().finally(()=>{
      window.location.reload()
    })
  }

  let value = {
    user: user,
    authState: authState,
    renderState: renderState,
    onboardState: onboardState,
    login: login,
    logout: logout,
    focusState: focusState,
    firebaseApp: firebaseApp,
    firebaseAuth: firebaseAuth,
    firebaseFunctions: firebaseFunctions,
    // firebaseMessaging: firebaseMessaging,
    firebaseAnalytics: firebaseAnalytics,
    // fcmToken: fcmToken,
    // fcmMessages: fcmMessages,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  return React.useContext(AuthContext);
}

async function doLogin(accounts: string[], web3: Web3): Promise<boolean> {
  const account = accounts[0];


  const messageEncoded = `0x${Buffer.from(Config.signMessage, "utf8").toString("hex")}`;
  const signature = await web3.eth.personal.sign(
    messageEncoded,
    account,
    "Example password"
  );

  const customToken: any = await firebaseLogin(account, signature)
  if (customToken) {
    await signInWithCustomToken(firebaseAuth, customToken);
    return true;
  } else {
    console.error("Error generating Firebase token.");
  }
  return false;
}
