import { useCallback, useEffect } from 'react';
import Loading from '../loading/index';
import style from './index.module.scss'

const defaultProps = {
    isEmpty: false,
    isLoading: false,
    onReachBottom: () => { }
};
type IProps = {
    isEmpty: boolean,
    isLoading?: boolean,
    triggerID?: string,
    onReachBottom: () => void
} & typeof defaultProps;

const ScrollLoading = (props: IProps) => {
    const { isLoading, isEmpty, onReachBottom, triggerID } = props

    const handelDocumentScroll = useCallback(() => {
        const clientHeight = document.documentElement.clientHeight
        const scrollTop = document.documentElement.scrollTop
        const scrollHeight = document.documentElement.scrollHeight


        if (scrollTop + clientHeight >= scrollHeight) {
            if (isLoading || isEmpty) {
                return
            }
            onReachBottom()
        }
    }, [isLoading, isEmpty, onReachBottom])

    const handelDivScroll = useCallback(() => {
        if (triggerID) {
            const trigger = document.getElementById(triggerID)
            if (trigger) {
                var scrollHeight = trigger.scrollHeight;
                var scrollTop = trigger.scrollTop;
                var clientHeight = trigger.clientHeight;
                if (scrollTop + clientHeight >= scrollHeight) {
                    onReachBottom()
                }
            }
        }
    }, [onReachBottom, triggerID])

    useEffect(() => {
        if (triggerID !== undefined) {
            const trigger = document.getElementById(triggerID)
            if (trigger !== null) {
                trigger.onscroll = handelDivScroll
            }
        } else {
            window.addEventListener('scroll', handelDocumentScroll)
        }

        return () => {
            if (triggerID !== undefined) {
                const trigger = document.getElementById(triggerID)
                if (trigger !== null) {
                    trigger.removeEventListener('scroll', handelDivScroll)
                }
            } else {
                window.removeEventListener('scroll', handelDocumentScroll)
            }
        }
    }, [handelDivScroll, handelDocumentScroll, triggerID])
    return <div>
        {isEmpty ? <div className={style.empty}>- Empty -</div> : <Loading show={isLoading} />}
    </div>
}

ScrollLoading.defaultProps = defaultProps

export default ScrollLoading