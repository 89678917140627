import { combineReducers } from 'redux';
import user from './user';
import links from './links';
import nfts from './nfts';
import sync from './sync';


export default combineReducers({
  user,
  links,
  nfts,
  sync
});
