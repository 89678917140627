import { useEffect, useState } from 'react'
import GridItem from '../../../components/grid/Item'
import GridBootstrap from '../../../components/grid/Index'
import { useAuth } from '../../../contexts/AuthContext'
import { Itoken } from '../../../types'
import { getTokens } from '../../../api/tokens'
import ScrollLoading from '../../../components/scrollLoading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import style from './Nfts.module.scss'

const defaultProps = {
    // exclude: []
};
type IProps = {
    filter?: string,
    exclude?: Array<any>,
    trigger?: string,
    onSelected?: Function
} & typeof defaultProps;


const Nfts = (props: IProps) => {
    const { onSelected, filter, exclude } = props
    const { user } = useAuth()

    const [pageTokens, setPageTokens] = useState<Itoken[]>([])
    const [allTokens, setAllTokens] = useState<Itoken[]>([])

    const [selected, setSelected] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const [isEmpty, setIsEmpty] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1)

    const limit = 20

    useEffect(() => {
        if (allTokens.length === 0 && user?.uid) {
            setLoading(true)
            getTokens(user?.uid, 0, 0).then(res => {
                if (res.length === 0) {
                    setIsEmpty(true)
                } else {
                    setAllTokens(res)
                }
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [allTokens.length, user?.uid])

    useEffect(() => {
        if (allTokens.length > 0) {
            setPageTokens(allTokens.slice(0, page * limit))
        }
    }, [page, allTokens])


    const view = filter ? <div>
        <GridBootstrap>
            {allTokens.filter((v: any) => {
                if (exclude) {
                    return !exclude.includes(v.id)
                } else {
                    return true
                }
            }).filter((v: any) => v.name.toLowerCase().includes(filter.toLowerCase())).map((v: any, i: any) => (
                <GridItem data={v} key={v.id} onClick={() => {
                    if (onSelected) {
                        setSelected(v.id)
                        onSelected && onSelected(v)
                    }
                }} badge={<div>
                    {v.id === selected && <div className={style.checkedIcon} >
                        <FontAwesomeIcon icon={["fal", "check-circle"]} />
                    </div>}
                </div>} />
            ))}
        </GridBootstrap>
    </div> : <div>
        <GridBootstrap>
            {pageTokens.filter((v: any) => {
                if (exclude) {
                    return !exclude.includes(v.id)
                } else {
                    return true
                }
            }).map((v: any, i: any) => (
                <GridItem data={v} key={v.id} onClick={() => {
                    if (onSelected) {
                        setSelected(v.id)
                        onSelected && onSelected(v)
                    }
                }} badge={<div>
                    {v.id === selected && <div className={style.checkedIcon} >
                        <FontAwesomeIcon icon={["fal", "check-circle"]} />
                    </div>}
                </div>} />
            ))}
        </GridBootstrap>
        <ScrollLoading onReachBottom={() => {
            setPage((preState) => {
                return preState + 1
            })
        }}
            isLoading={loading}
            isEmpty={isEmpty}
            triggerID={props.trigger}
        />
    </div>

    return allTokens ? view : null
}

Nfts.defaultProps = defaultProps;
export default Nfts

