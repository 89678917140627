import style from './Index.module.scss'
import { Col, Container, Row } from "react-bootstrap";
import { NiftyAuthenticationState, useAuth } from "../../../contexts/AuthContext";
import Commonwrap from "../../../components/commonwrap/Index";
import { useState } from 'react';
import Niftynetlink from '../niftynetlink/Index';
import Tab from '../tab/Index';
import Linkspreview from '../links/Linkspreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Header from '../../../components/header/Index';
const defaultProps = {
    showHeader: true,
    showRight: true,
    showTabs: true,
    showPreview: true,
};
type IProps = {
    showHeader: boolean,
    showRight: boolean,
    showTabs: boolean,
    showPreview: boolean,
    children: React.ReactElement,
    slotRight?: React.ReactElement
} & typeof defaultProps;

export default function Layout(props: IProps) {
    const { showHeader, showRight, showTabs, children, slotRight, showPreview } = props
    const [showMobilePreview, setShowMobilePreview] = useState<boolean>(false)
    const { authState } = useAuth()

    return (
        <div>
            {showHeader &&
                <Header />
            }
            <Container fluid>
                <Row style={{ height: 'calc(100vh - 3.5rem)' }}>
                    <Col style={{ padding: '0', height: '100%', overflowY: 'auto' }}>
                        {(authState === NiftyAuthenticationState.AUTHENTICATED && showTabs) &&
                            <div>
                                <div className="d-lg-none d-xl-none d-xl-block"><Niftynetlink /></div>
                                <Tab />
                            </div>
                        }
                        <div>
                            <Commonwrap>
                                {children}
                            </Commonwrap>
                            <div className='safe-distance'></div>
                        </div>
                    </Col>
                    {showRight &&
                        <Col md='4' lg='4' className="d-none d-lg-block " style={{ borderLeft: '2px solid #dcdcdc', padding: '0', height: '100%' }}>
                            {slotRight ? slotRight :
                                <div>
                                    <Niftynetlink />
                                    <Linkspreview />
                                </div>
                            }
                        </Col>
                    }
                </Row>
                {showPreview && <div className="d-lg-none d-xl-none d-xl-block">
                    {showMobilePreview &&
                        <Linkspreview type='desktop' />
                    }
                    {showMobilePreview ? <div className={style.showMobilePreviewBtn} onClick={() => {
                        setShowMobilePreview(false)
                    }}>
                        <FontAwesomeIcon icon={["fas", "times"]} color='#ffffff' style={{ fontSize: '1.2rem' }} />
                    </div> : <div className={style.showMobilePreviewBtn} onClick={() => {
                        setShowMobilePreview(true)
                    }}>
                        <FontAwesomeIcon icon={["fas", "eye"]} color='#ffffff' style={{ fontSize: '1.2rem', marginRight: '0.5rem' }} />
                        Preview
                    </div>}
                </div>}
            </Container>
        </div>
    );
}