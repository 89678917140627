import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Container, Pagination, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { getCommunityBySlug, getCommunityUsersBySlug } from '../../api/community';
import Avatar from '../../components/avatar';
import Hexagon from '../../components/avatar/Hexagon';
import Loading from '../../components/loading';
import ScrollLoading from '../../components/scrollLoading';
import Images from '../../images';
import { Icommunity, Ilink, Itoken, Iuser } from '../../types';
import style from './Index.module.scss'
import Config from '../../../src/utils/config'
import ReactMarkdown from 'react-markdown'
import { useHistory } from 'react-router-dom';
import Header from '../../components/header/Index';

const Collection = (props: { match: { params: { name: any } } }) => {
    const [community, setCommunity] = useState<Icommunity>()
    const [page, setPage] = useState<number>(1)
    const [users, setUsers] = useState<Iuser[]>([])
    const [isInit, setIsInit] = useState<boolean>(true)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [isEmpty, setIsEmpty] = useState<boolean>(false)

    const history = useHistory()

    useEffect(() => {
        if (props.match.params.name) {
            getCommunityBySlug(props.match.params.name).then(data => {
                setCommunity(data)
            }).finally(() => {
                setIsInit(false)
            })
        }
    }, [props.match.params.name])

    useEffect(() => {
        if (props.match.params.name) {
            if (isEmpty) {
                return
            }
            setLoading(true)
            getCommunityUsersBySlug(props.match.params.name, page).then(res => {
                if (res.length === 0) {
                    setIsEmpty(true)
                } else {
                    if (page <= 1) {
                        setUsers(res)
                    } else {
                        setUsers((pre) => {
                            return [...pre, ...res]
                        })
                    }
                }
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [page, props.match.params.name])


    let social_links: { value: any, icon: any }[] = []
    if (community) {
        if (community.metadata) {
            if (community.metadata.external_url) {
                social_links.push({
                    value: community.metadata.external_url,
                    icon: ['fas', 'globe']
                })
            }
            if (community.metadata.discord_url) {
                social_links.push({
                    value: community.metadata.discord_url,
                    icon: ['fab', 'discord']
                })
            }
            if (community.metadata.instagram_username) {
                social_links.push({
                    value: 'https://www.instagram.com/' + community.metadata.instagram_username,
                    icon: ['fab', 'instagram-square']
                })
            }
            if (community.metadata.twitter_username) {
                social_links.push({
                    value: 'https://www.twitter.com/' + community.metadata.twitter_username,
                    icon: ['fab', 'twitter']
                })
            }
            if (community.metadata.telegram_url) {
                social_links.push({
                    value: community.metadata.telegram_url,
                    icon: ['fab', 'telegram']
                })
            }
        }
    }

    return <div>
        <Loading show={isInit} fullScreen={true} background="white" />
        <Header />
        <div className='page-container'>
            <div className={style.banner}>
                <img src={community?.banner_image_url} alt="banner" />
            </div>
            <Container>
                <div className='mw-700'>
                    <Row>
                        <Col sm="12" md='3' lg='2' className="d-flex justify-content-center justify-content-md-start">
                            <Avatar src={community?.image_url} size="6.5rem" thumbnail margin="-2rem 0 0 0"></Avatar>
                        </Col>
                        <Col sm="12" md='6' lg='8' className="d-flex flex-column align-items-md-start align-items-center my-2">
                            <h3 className="text-dark">{community?.name}</h3>
                            {/* <p>
                            <span>4.3K Followers</span>
                        </p> */}
                        </Col>
                        <Col sm="12" md='3' lg='2' className="d-flex flex-row justify-content-center justify-content-md-end align-items-center">
                            <ButtonGroup aria-label="share" style={{ backgroundColor: '#F8F8F8' }}>
                                {social_links.map(v => {
                                    return <Button as="a" href={v.value} target='_blank' variant="outline-secondary" key={v.value}  >
                                        <FontAwesomeIcon icon={v.icon} />
                                    </Button>
                                })}

                            </ButtonGroup>
                        </Col>
                    </Row>
                </div>
                {community?.description &&
                    <div className={['mw-700', 'mt-4', style.community_description].join(' ')}>
                        <ReactMarkdown>{community.description}</ReactMarkdown>
                    </div>
                }
            </Container>
            <Container>
                <div className={style.table}>
                    <div className={style.tableHeader}>
                        Holders
                    </div>
                    <div className={style.tableBody}>
                        <div className={style.tableRow}>
                            <div className={style.tableCel}>User</div>
                            <div className={style.tableCel}>NFTs</div>
                        </div>
                        {users.map(user => {
                            const avatar = user.avatar || Images.default_avatar
                            const avatar_verified = avatar.includes('source=nft')
                            const tokens = user.tokens || { list: [], count: 0 }
                            const left_token_count = tokens.count - tokens.list.length
                            const account = user.accounts[0].account.slice(0, 5) + '...' + user.accounts[0].account.slice(0. - 3)
                            let username = ''
                            if (user.display_name && user.display_name.length > 0) {
                                username = user.display_name
                            } else {
                                username = `@${user.username}`
                            }

                            //get an user social link
                            let user_social_link: { [key: string]: any } = {
                                'Twitter': null,
                                'Discord': null
                            }
                            if (user.social_links) {
                                for (const key in user_social_link) {
                                    const link = Config.social_links[key];
                                    const target = user.social_links[key]
                                    if (target) {
                                        link.value = 'https://' + link.host + target
                                        user_social_link[key] = link
                                    }
                                }
                            }

                            return <div className={style.tableRow} >

                                <div className={style.tableCel}>
                                    <div className={style.userAvatar}>
                                        <a className={style.blockA} href={`/${user.username}`} key={user.id} onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            history.push(`/${user.username}`)
                                            return false
                                        }}>
                                            {avatar_verified ? <Hexagon borderColor='#8E20BC' src={avatar} size='50px' /> : <Avatar src={avatar} size='45px' thumbnail rounded />}
                                        </a>
                                    </div>
                                    <div className={style.userInfo}>
                                        <a className={style.blockA} href={`/${user.username}`} key={user.id} onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            history.push(`/${user.username}`)
                                            return false
                                        }}>
                                            <strong>{username}</strong>
                                        </a>
                                        <div>
                                            {Object.keys(user_social_link).map(k => {
                                                const l = user_social_link[k]
                                                if (l !== null) {
                                                    return <a href={l.value} target={'_blank'} rel="noreferrer" key={l.value} className='me-3' onClick={(e) => {
                                                        e.stopPropagation()
                                                    }}>
                                                        <FontAwesomeIcon icon={l.icon} color={'#000'} />
                                                    </a>
                                                } else {
                                                    return null
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.tableCel}>
                                    {tokens.list.map((token: Itoken) => {
                                        // return <Avatar key={token.id} src={token.image_url} size={'40px'} rounded margin='0 5px 0 0' />
                                        return <div key={token.id} className={style.tokenPreviewItem} style={{ backgroundImage: `url(${token.image_url})` }}></div>
                                    })}
                                    {(left_token_count > 0) && `+${left_token_count}`}
                                </div>
                            </div>
                        })}
                    </div>
                    <ScrollLoading onReachBottom={() => {
                        setPage((page) => {
                            return page + 1
                        })
                    }} isLoading={isLoading} />
                </div>

            </Container>
        </div>
        <div style={{ height: '300px' }}></div>
    </div>
}

export default Collection