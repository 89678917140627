export const DEPLOY_TARGET = process.env.REACT_APP_TARGET

export const HOSTNAME = DEPLOY_TARGET === 'prod' ? "niftynet.com" : 'niftynet.xyz';
export const HOST_BACKEND = DEPLOY_TARGET === 'prod' ? "https://backend.niftynet.com" : 'https://backend.niftynet.xyz';

const development: boolean = process.env.NODE_ENV === "development";
export default function isDev(): boolean {
  return development;
}

export function isNumeric(str: string) {
  if (typeof str != "string") return false; // we only process strings!
  return !isNaN(+str);
}

export function blobToBase64(blob: Blob): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export class Debounced {
  public use = (func: Function, delay: number = 600): Function => {
    let timer: any
    return (...args: any) => {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        func.apply(this, args)
      }, delay)
    }
  }
}

export function isEmpty(d: any) {
  if (d == null || d === undefined || d === 'undefined') {
    return true
  }
  if (typeof d === 'number') {
    return d < 0
  }
  if (typeof d === 'string') {
    return d.length === 0
  }

  if (typeof d === 'object') {
    return Object.keys(d).length > 0
  }
}